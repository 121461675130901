const api = {
    signUp: '/api/signup', // 注册
    login: '/api/login', // 登录
    logout: '/api/logout', // 退出登录
    signCode: '/api/code/$0/signup', // 注册时获取验证码
    loginCode: '/api/code/$0/signin', // 登录时获取验证码
    forGetCode: '/api/code/$0/retrieve/pwd/$1', // 忘记密码获取验证码
    retrievePas: '/api/retrieve/pwd', // 忘记密码
    downloadFile: '/api/file/download', // 下载文件
    personalCompany: '/api/company/personal', // 个人版信息

    // 操作当前用户所选企业 get：获取  put：修改
    userInCompany: '/api/user/in/company',

    addCompany: '/api/company', // 添加公司
    companyCategory: '/api/company/category', // 获取公司种类
    companyType: '/api/company/type', // 获取公司类型
    getCompanyInfo: '/api/company/down/$0/info', // 获取下游公司信息
    getCompanyDetail: '/api/company/$0/detail', // 获取公司信息(速度优化版)
    getUpCompanyId: '/api/company/down/$0/canonical', // 获取直属版直属上游id
    getEnterpriseBusinessInfo: '/api/enterprise/businessInfo', // 获取企业工商信息
    getCompanyBaseInfo: '/api/company/$0/info', // 获取公司基本信息
    getCompanyScore: '/api/company/$0/score', // 获取公司资质分数
    companyBusiness: '/api/all/business', // 获取公司业务范围
    getCompanySelf: '/api/companies', // 获取自己关联公司
    changeCurrCompany: '/api/company/change', // 切换公司
    editCompanyInfo: '/api/company/down/$0/info', // 修改公司信息
    deleteCompany: '/api/company/$0', // 删除企业
    criterionStatusTask: '/api/activity/criterion/$0/task/status', // 删除企业
    getCompanyReport: '/api/company/$0/report/pdf', // 查看企业pdf资质报告
    scoreShowToggle: '/api/company/score/show', // 设置用户打开公司详情页是否显示公司分数

    syncpromoteProduct: '/api/$0/promote/products/$1/syncpromote', // 下游同步列表显示
    getAipOcrBusinessLicence: '/api/aipOcr/businessLicence', // 文字识别接口

    searchUser: '/api/user/search', // 搜索成员
    addUser: '/api/user/$0/company/$1/role/$2', // 添加成员
    deleteUser: '/api/user/$0/company/$1', // 删除成员
    changeUser: '/api/user/$0/company/$1/role/$2', // 修改成员权限
    transferUser: '/api/user/$0/company/$1/transfer', // 转移权限
    exitUser: '/api/user/company/$0', // 退出企业
    getDesignation: '/api/user/designation', // 读取用户备注名

    userNotice: '/api/user/notice', // 查看通知
    deleteMarkVisits: '/api/task/item/market/{marketManagementId}/visits', // 分包任务拜访列表，批量删除
    userInfo: '/api/user/info', // 用户信息
    uploadImg: '/api/upload/img', // 上传头像
    changePass: '/api/user/change/pwd', // 修改密码
    updataUserInfo: '/api/user/info', // 修改用户信息
    getAllPro: '/api/provinces/all', // 获取所有省份
    getAllcitys: '/api/$0/cities', // 获取所有城市
    getArea: '/api/area', // 获取城市区县接口
    getDistrictByCity: '/api/$0/district', // 获取城市的所有区域
    UserList: '/api/users', // 查看用户列表
    workloadUsers: '/api/workload/users', // 下游公司用户基础信息列表
    getRole: '/api/user/company/$0/role', // 查看用户在该公司权限
    getSelfRole: '/api/user/company/role', // 查看自己在当前公司的角色权限
    manageInfo: '/api/user/$0/company/$1', // 查看用户信息

    approveBtn: '/api/company/$0/apply/authentication', // 申请企业认证

    addProduct: '/api/products', // 添加产品列表
    addProduct1: '/api/products/$0', // 添加产品列表
    getCfda: ' /api/product/$0', // 查看cfda
    addAction: '/api/productinfo/$0/company/$1', // 添加产品
    searchProduct: '/api/productinfo/$0', // 查看产品信息
    getCategories: '/api/product/categories', // 获取产品类型
    deleteProduct: '/api/productinfo/$0', // 删除产品
    getDepartment: '/api/departments', // 获取科室
    getAreas: '/api/activity/registers/$0/area', // 获取地区
    addBase64: '/api/upload/img/base64', // 添加图片 Deprecated
    annoyAddBase64: '/api/annoy/upload/img/base64', // 未登录用户添加图片
    postBase64: '/api/upload/img/base64/v2', // 添加图片
    getDiseases: '/api/diseases', // 获取疾病
    getPlanParticipants: '/api/activityPlan/participants/$0', // 获取活动计划人数
    getPlanTrainingUsers: '/api/activity/internal/training/users', // 内部培训邀请人查询用户
    internalParticipants: '/api/$0/activity/internal/$1/participants', // 内部培训参与人

    getInvestments: '/api/investments', // 获取招商信息列表
    getProductInfos: '/api/productInfos/$0', // 获取产品信息列表
    getChannels: '/api/channels', // 获取招商渠道
    getContacts: '/api/contacts/$0', // 获取联系人
    getHospitalAreas: '/api/hospitalAreas', // 获取联系人
    publishMes: '/api/investments', // 发布招商信息
    getDetail: '/api/investments/$0/detail', // 招商信息详情
    deleteDetail: '/api/investments/$0', // 删除招商信息
    previewInfo: '/api/investments/$0/preview', // 预览招商信息
    previewInfo1: '/api/investments/$0/share', // 预览招商信息
    previewInfo2: '/api/investments/preview/$0', // 预览招商信息
    getStatData: '/api/investments/$0/stat', // 预览招商信息
    getStatDataByPhone: '/api/investments/$0/stat/phone', // 预览招商信息

    getBuyList: '/api/purchase/up/page', // 获取求购列表
    getBuyListInfo: '/api/purchase/$0/up/detail', // 获取求购详情
    deleteBuyListInfo: '/api/purchase/$0/uninterested', // 不感兴趣
    getInvestmentsList: '/api/investments/$0/selectList', // 获取招商信息
    pushBussiness: '/api/purchase/$0/push/$1', // 获取招商信息

    getMessageList: '/api/message/list', // 获取消息列表
    getMessageInfo: '/api/message/$0/detail', // 获取消息详情
    deleteMessageInfo: '/api/message/$0/delete', // 删除消息
    getMessageCount: '/api/message/count', // 删除消息

    getCountHome: '/api/home/$0/resource/count', // 首页获取数据
    getHome: '/api/investments/stat', // 首页招商统计
    readAll: '/api/message/allRead', // 全部已读

    getHospitals: '/api/hospitals', // 获取医院
    getCustomers: '/api/customers/titles', // 获取职称
    getPost: '/api/customers/posts', // 获取职位
    addCustomers: '/api/customers/type', // 添加客户类型
    getCustomersList: '/api/customers/types', // 获取客户类型
    addCustomer: '/api/customers/add', // 添加客户
    addCustomer2: '/api/customer', // 新增临床医护
    addCustomerExamine: '/api/customers/add/check/$0', // 添加客户前的三要素校验
    getCustomerInfoList: '/api/customers/list', // 获取客户列表
    getPromote: '/api/promote/products', // 查询推广产品列表
    searchProductAndroid: '/api/product/search', // 查询推广产品列表
    searchProductDetail: '/api/product/search/detail', // 查询推广产品详情
    searchFactory: '/api/product/factory', // 搜索厂商
    searchDrug: '/api/product/drug', // 搜索规格
    addPromotion: '/api/promote/product', // 添加推广产品
    getPromotion: '/api/promote/products', // 获取推广产品
    getPromotionDetail: '/api/promote/product/$0', // 获取推广产品详情

    SynchCustomer: '/api/visit/add', // 同步拜访计划
    recordsList: '/api/visit/records', // 拜访计划列表
    recordsDetail: '/api/visit/detail/$0/$1', // 拜访计划详情
    deleteRecords: '/api/visit/delete/$0', // 删除拜访计划
    syncsList: '/api/visit/syncs', // 同步列表
    visitDetail: '/api/visit/detail/$0', // 同步列表
    visitDetailVersion2: '/api/visit/$0', // 临床拜访详情  --第二版接口
    updateVisitDetail: '/api/visit/record/update/$0', // 更新临床拜访详情
    promoteGetType: '/api/promote/product/$0/template/$1', // 獲取產品關鍵信息和客戶反饋
    visitData: '/api/visit/companys/$0', // 拜访数据列表
    getUnRefencedVisits: '/api/visit/un_referenced', // 分页查询可添加到任务中的临床拜访记录
    visitDataInitial: '/api/visit/visitProduct/companys/$0', // 拜访数据列表 --准入登记时使用，准入登记的列表逻辑不变，和原先一致
    createList: '/api/visit/createUsers', // 创建人
    visitProduct: '/api/visit/products', // 产品列表
    reportFileList: '/api/report/files', // 我的报告列表
    uploadReport: '/api/report/upload', // 上传报告
    Upload: '/api/upload_file', // 上传文件
    UploadCompress: '/api/upload/compress', // 上传图片 压缩
    uploadCompressImg1M: '/api/upload/compress?compressSize=1024', // 上传图片并压缩至1M
    uploadCompressImg3M: '/api/upload/compress?compressSize=3072', // 上传图片并压缩至3M
    deleteReport: '/api/report/file/$0', // 上传文件
    reportUploadList: '/api/report/file/submitter/$0', // 上傳人列表

    // 资讯
    releaseInformationDetail: '/api/information/release/$0', // 发布资讯详情
    informationBrowseStat: '/api/information/release/$0/browse/stat', // 浏览情况
    releaseInformation: '/api/information/$0/release', // 发布资讯
    updateReleaseInformation: '/api/information/release/$0', // 编辑发布的资讯

    informationGroup: '/api/$0/information/group/fold', // 服务商分组
    informationCompanies: '/api/information/companies/$0', // 服务商选择列表
    toggleInformationRelease: '/api/information/release/$0/toggle', // 切换资讯投递对象
    informationReleaseContribute: '/api/information/release/$0/contribute', // 服务商贡献情况
    informationReleaseTrend: '/api/information/release/$0/trend', // 总趋势
    downloadCustomerReadStatistics: '/api/information/download/$0/customer/read', // 临床客户浏览数据下载
    downloadProviderContribute: '/api/information/download/$0/contribute', // 服务商贡献清单下载
    checkCanRead: '/api/wx/app/$0/is/information', // 检查是否能阅读
    informationList: '/api/wx/app/$0/informations', // 资讯列表
    informationDetail: '/api/information/$0', // 资讯详情
    sendReadDurationm: '/api/wx/app/information/score/read/duration', // 时长、浏览位置、评分
    chooseCompanyList: '/api/wx/app/$0/company/list', // 选择企业列表
    chooseCustomerList: '/api/wx/app/$0/customer/list', // 选择客户列表
    sendInformation: '/api/wx/app/send/information', // 投递
    sendErrorCustomers: '/api/wx/app/send/error/$0', // 投递失败客户列表

    TemplatesList: '/api/activity/templates', // 模版列表
    CategoriesList: '/api/activity/categories', // 类别列表
    AddCategories: '/api/activity/category', // 新增活动类型
    CategoriesDetail: '/api/activity/category/$0', // 活动类型详情
    RegistersList: '/api/activity/registers', // 活动登记列表
    choosePlans: '/api/activity/registers/plans', // 计划选择
    plansList: '/api/activity/plans', // 计划选择
    AddPlans: '/api/activity/plan', // 申请计划
    activityObject: '/api/activity/object', // 活动对象 推广对象或培训对象列表或添加
    activityObjectAct: '/api/activity/object/$id', // 活动对象 推广对象或培训对象 编辑或删除
    registerMenu: '/api/activity/register/$0/menu', // 活动登记tab
    PlansDetail: '/api/activity/plan/$0', // 申请计划
    pharmacyAmount: '/api/visit/pharmacy/$0/amount', // 判断药店创建是否超过十条
    approvePlans: '/api/activity/plan/$0/approve', // 批准活动
    refusePlans: '/api/activity/plan/$0/refuse', // 拒绝活动
    approverList: '/api/activity/plan/approver', // 申请人列表
    registersDetail: '/api/activity/registers/$0', // 活动登记详情
    getPaperSignList: '/api/activity/registers/$0/paper/sign', // 获取纸质签到信息
    getCheckedNum: '/api/activity/$0/participant/signed/num', // 获取活动的已签到人数
    draftsList: '/api/activity/drafts', // 活动登记草稿箱列表
    registerPlanTypeList: '/api/activity/register/$0/promote/type', // 活动登记类型列表
    activityPlanTypeList: '/api/activity/plan/$0/promote/type', // 活动计划类型列表
    batchUploadActivityPlanUsers: '/api/activity/internal/training/batchadd/users', // excel导入内部培训邀请人获取用户列表
    internalSignExcel: '/api/activity/internal/$0/sign/excel', // 客户补签导入excel
    getInternalSignProgress: '/api/commons/task/cache/task/$0', // 导入过程
    getTrainingUploadProgress: '/api/activity/internal/training/batchadd/users/progress/$0', // 获取excel上传的进度
    promoteTypeList: '/api/activity/promote/type', // 推广项目种类列表
    queryPromoteTypes: '/api/promote/type', // 根据推广项目ID查询推广项目类型
    planDraftsList: '/api/activity/plans/drafts', // 活动计划草稿箱列表
    changeApproval: '/api/activity/registers/$0/approval', // 活动登记修改状态
    withdraw: '/api/activity/registers/$0/withdraw', // 撤回活动登记
    withdrawReason: '/api/withdraw/reason/$0', // 查询拒绝活动理由
    getChooseDetail: '/api/activity/choose/plans/$0', // 原活动
    getPlanProduct: '/api/activity/products', // 新建计划的推广产品
    reportDetail: '/api/report/file/$0', // 报告详情
    reportDelete: '/api/report/file/$0/company/$1', // 删除报告
    reportUpload: '/api/report/upload/$0', // 报告详情
    ChangeNote: '/api/user/$0/company/$1', // 修改备注
    ChangeDepart: '/api/user/company/$0/department', // 修改部门名称
    divisions: '/api/divisions', // 读取公司所有部门名称列表（不分级）
    downDivisions: '/api/down/divisions', // 读取下游公司所有部门名称列表（不分级）
    exportVisit: '/api/visit/download/$0/$6', // 导出数据
    getProductVisitInfo: '/api/visit/products/$0/visitInfo', // 产品临床拜访详情
    getPharmacyProductVisitInfo: '/api/visit/pharmacy/products/$0/visitInfo', // 产品药店拜访详情
    pharmacyVisitCreateUsers: '/api/pharmacy/visit/company/$0/createUsers', // 药店拜访创建人列表
    pharmacyProductVisit: '/api/visit/pharmacy/product/$0', // 产品药店拜访删除
    getChannelProductVisitinfo: '/api/visit/channel/products/$0/visitInfo', // 产品渠道拜访详情

    promotionTaskAssign: '/api/promote/task/$promoteTaskId/sign/assign/$assignUserId', // (post)乙方指派推广任务电子签章
    promotionTaskAssignmentSee: '/api/promote/task/$promoteTaskId/sign/assign/list', // (get)乙方查看指派推广任务电子签章列表
    taskSignatureDoing: '/api/promote/task/$0/signature', // 开启推广任务电子签章
    taskSignatureToggle: '/api/promote/task/$0/signature', // 查询推广任务接收方电子签章开关
    taskSignatureUrl: '/api/promote/task/$0/collection/batch/signature/url', // 获取任务签章链接
    withdrawlTaskSignature: '/api/promote/task/$0/signature/withdraw', // 药事（乙方）撤回推广任务电子签章
    fadadaEsignResult: '/api/fadada/extsign/notify/$0/result', // 查询签署结果异步通知回调结果
    projectResource: '/api/promote/task/$0/unlimited', // 项目来源
    serviceList: 'api/promote/task/$0/unlimited/serviceItem', // 推广项目下拉
    projectResouceDownload: '/api/promote/task/$0/unlimited/excel/async', // 项目来源下载
    idDelete: '/api/company/$0/deleted', // 查询企业是否已删除
    contanctData: '/api/contact/$0', // 签约
    searchCompany: '/api/company/authenticated', // 搜索公司
    partyaList: '/api/contact/partya/$0', // 上游查看签约列表
    partybList: '/api/contact/partyb/$0', // 下游查看签约列表
    getPromoteList: ' /api/promote/tasks', // 推广任务列表
    partyaObj: '/api/contact/$0/partya', // 签约对象
    addPromote: '/api/promote/task/basic', // 创建推广任务
    getPromoteTask: '/api/promote/task/items', // 获取服务项目
    addPromoteTask: '/api/promote/task/$0/item', // 添加服务项目
    itemsPromoteTask: '/api/promote/task/$0/items', // 查询服务项目
    deletePromoteTask: '/api/task/$0/item/$1', // 删除服务项目
    getTaskBasic: '/api/promote/task/$0/basic', // 基础信息
    maintenancegetTaskBasic: '/api/task/$0/terminal/maintenance/option/detail/page', // 基础信息
    getTaskNote: '/api/promote/task/$0/basic/status', // 获取推广计划审批详情
    getMarketProduct: '/api/market/products/$0', // 获取产品列表
    getMarketUsers: '/api/task/$0/item/market/users', // 联系人列表
    addMarket: '/api/task/item/$0/market', // 添加市场列表
    getMarket: '/api/markets/$0', // 市场列表
    getMarketVisits: ' /api/task/item/market/$0/visits', // 市场列表
    visitsDetail: ' /api/task/item/market/$0/visits', // 市场列表
    exportMarketVisits: '/api/market/download/$0', // 数据导出
    promotetask: '/api/task/item/$0/activities', // 学术活动
    itempromotetask: '/api/task/$0/item/activity/items', // 选择学术活动
    addpromotetask: '/api/task/item/$0/activity', // 添加学术活动
    downloadPdf: '/api/promotetask/activity/download/$0/$1', // pdf下载
    deleteItempromotetask: '/api/promotetask/activity/$0/$1', // 删除
    getpromotereport: '/api/task/item/$0/report', // 报告
    getAttachment: '/api/task/item/$0/attachments', // 附件分页
    addAttachment: '/api/task/item/$0/attachment', // 附件添加
    deleteREPORT: '/api/task/item/$0/report/$1', // 删除报告
    updateReport: '/api/task/item/$0/report/$1', // 更新报告关联关系吧
    putTask: '/api/promote/task/$0', // 提交推广
    getTaskOperationCount: '/api/promote/batchtask/operation/count', // 查看进行中的快速任务集操作进程数
    batchTaskOperations: '/api/promote/batchtask/operations', // 快速任务集的操作进程列表
    batchTaskSnapshot: '/api/promote/batchtask/operation/$0/snapshot', // 快速任务集快照
    removeBatchTask: '/api/promote/batchtask/operation/$0', // 清除快速任务集的操作进程
    marketPreview: '/api/task/item/$0/market/preview', // 预览
    editSettlement: '/api/promote/task/$0/item/settlement', // 修改金额
    downloadmarket: '/api/task/download/visit/$0/pdf', // 明细下载
    downloadmarketpdf: '/api/task/download/$0/visit/summary', // 临床拜访明细下载
    downloadLogin: '/api/task/download/$0/activity/summary', // 学术推广登记表下载
    participantsList: '/api/activity/$0/participants', // 参与人列表
    activityparticipants: '/api/activity/plan/$0/participants', // 参与人列表
    participantsSign: '/api/activity/internal/$0/sign', // 内部培训补签到
    registerparticipants: '/api/activity/register/$0/participants', // 参与人列表
    contactsList: '/api/activity/$0/$1/contacts', // 联系人列表
    participantList: '/api/activity/$0/$1/participants', // 联系人列表
    contactsactivity: '/api/activity/$0/contacts', // 联系人列表
    contactsPart: '/api/customers/createUsers/$0', // 创建人列表
    pDown: '/api/activity/$0/$1/participants/download', // 参与人名单下载
    signFn: '/api/activity/participants/$0/sign', // 补签
    deleteparticipants: '/api/activity/participants/$0/$1', // 删除参与人
    resetParticipantSignedStatus: '/api/activity/participants/$0/resetSigned', // 清空活动参与人签到状态和签到时间
    signedOWN: '/api/activity/$0/$1/sign/download', // 签到表下载
    contactsListPart: '/api/customers/contacts/$0', // 联系人列表
    customerProfession: 'api/customers/$1/sync/janus/user', // 已认证联医详情
    contactsDetail: '/api/customers/$1/contacts/detail/$0', // 联系人详情
    customerCode: '/api/$0/customer/$1/activation/code', // 客户激活码获取
    unbindCustomer: '/api/$0/customer/$1/unbind', // 解绑与联医客户关联
    updateCustomer: '/api/customers/update/$0', // 编辑客户信息
    syncJanusAndCustomerUser: '/api/customers/$0/sync/janus/user', // 同步药事和联医用户
    syncSameName: '/api/customers/$0/sync/name/same', // 同步判断名字是否相同
    deleteBatch: '/api/customers/delete/batch', // 批量删除联系人
    activityPlanCheck: '/api/activity/plan/$0/check', // 校验联系人
    editContactsDetail: '/api/customers/update/$0/info', // 编辑客户信息--未通过审核的临床医护数据
    passEditContactsDetail: '/api/customers/update/$0', // 编辑客户信息--通过审核的临床医护数据

    addAgenda: '/api/activity/plan/$0/agenda', // 添加议程
    visitBatch: '/api/visit/$0/batch', // 批量删除
    visitBatchDelete: '/api/visit/batchDelete', // 临床拜访批量删除
    addInvitation: '/api/activity/plan/invitation/$0', // 添加邀请函
    PlanCheck: '/api/activity/plan/check', // 添加活动计划校验
    getInvitation: '/api/activity/plan/$0/invitation', // 查询邀请函
    InvitationDown: '/api/activity/plan/invitation/download/$0', // 邀请函下载
    InvitationUpdate: '/api/activity/plan/invitation/update/$0', // 邀请函更新
    tempStoreInvitationLetter: '/api/activity/plan/invitation/letter', // 邀请函暂存、读取暂存
    generateScreenshotImage: '/node/common/pageGenerateImage', // 生成截图
    agendaQueryList: ' /api/activity/plan/agendaQueryList/$0', // 查询议程
    updateagendaQueryList: '/api/activity/plan/agenda/update/$0', // 更新议程
    updateTaskSubvisionBudget: '/api/promote/task/$taskId/budget/subdivision', // 更新任务预算表-细分项目
    budgetget: '/api/promote/task/$0/budget', // 更新议程
    associateDe: '/api/customers/associate', // 批量删除
    activityChange: '/api/task/item/activity/$0', // 更新学术活动服务类型和结算金额
    taskChange: '/api/promote/task/$0/$1', // 更新议程
    activityDown: '/api/task/download/$0/activity/$1', // 更新议程
    budgetDown: '/api/task/download/$0/budget', // 预算表下载
    settlementDown: '/api/task/download/$0/settlement', // 结算表下载
    settlementget: '/api/promote/task/$0/settlement', // 结算表
    getSettlements: '/api/promote/task/$0/settlement/group', // 结算表新接口
    getQR: '/api/wx/app/activity/plan/$0/url', // 获取小程序路径
    deleteContact: '/api/customers/contacts/delete/$0/$1', // 删除联系人
    uploadCustomers: '/api/customers/upload/$1', // 批量导入客户
    customersZhi: '/api/customers/$0/users', // 客户指派列表
    associate: '/api/customers/associate/$0', // 批量指派
    contactsDetailList: '/api/customers/contacts/$0/user', // 批量指派
    deleteCustomers: '/api/customers/delete/$0', // 删除客户
    customersDown: '/api/customers/download/template', // 客户模版下载
    taskCustomers: '/api/customers/task/$0', // 查询进度
    deletePlan: '/api/activity/$0/plan/$1', // 删除计划
    deleteRegisters: '/api/activity/$0/registers/$1', // 删除计划登记
    downloadCso: '/api/promote/task/$0/cso/download', // 推广协议下载
    compressDown: '/api/task/download/$0/collection', // 压缩所有pdf下载
    asyncCompressDown: '/api/task/download/$0/collection/async', // 异步下载任务合集
    summaryActiveDown: '/api/activity/$0/$1/summary/download', // 学术总结下载
    contactDetail: '/api/contact/details/$0/$1', // 详情
    contactDelete: '/api/contact/$0', // 取消签约
    promoteLog: '/api/promote/task/$0/log', // 审批日志
    changeStatus: '/api/promote/task/$0/status', // 修改状态
    facilitatorList: ' /api/promote/facilitator/set/$0', // 服务商列表
    contactCompany: '/api/contact/company/$0', // 服务商列表
    protocolsList: '/api/contact/$0/protocols', // 服务商协议列表
    deleteCo: '/api/contact/$0', // 取消协议
    protocolBudget: '/api/contact/$0/protocol/$1/budget', // 更新预算
    protocolDetail: '/api/contact/$0/protocol/$1', // 协议详情
    protocolsLists: '/api/contact/partyb/$0/protocols', // 下游查看协议列表
    protocolDetails: '/api/contact/protocol/$0', // 下游查看协议列表
    partyaNameList: '/api/contact/partyb/$0/partyaName', // 下游查看签约公司名
    partyaSettlementList: 'api/settlement/stats/$0/receiverName', // 获取能创建结算凭证的上游列表
    downNumber: '/api/contact/$0/$1/down/number', // 下游查看协议号
    isWithinDate: '/api/contact/protocol/$0/isWithinDate', // 判断是否超过周期
    isWithinArea: '/api/contact/protocol/$0/isWithinArea', // 判断是否超过地域
    statusTwo: '/api/promote/task/status/$0', // 审批状态
    marketProducts: '/api/market/products/task/$0', // 审批状态
    downloadProtocol: '/api/contact/$0/protocol/download', // 审批状态
    freeze: '/api/contact/$0/freeze', // 冻结服务商
    contactfreeze: ' /api/contact/freeze/$0', // 解冻服务商
    complianceList: '/api/compliance/$0', // 合规标准分页
    complianceItems: '/api/compliance/$0/items', // 查询条件项
    productinfosList: '/api/productinfos/statistics', // 预算统计分页
    statisticsList: '/api/promote/tasks/statistics', // 预算统计详情
    getitems: '/api/task/$0/items', // 查询服务项
    additems: '/api/task/$0/item', // 添加服务项
    accessibleItems: '/api/task/$0/item/accessible', // 根据任务ID查询可以创建的服务项目
    accessibleItem: '/api/task/item/accessible', // 获取服务项目（和上一个区别在于taskId传法不同，其余参数不同）
    standardItem: '/api/budget/amount/settlement/standard/$0',
    marketsList: '/api/task/item/$0/markets', // 临床数据
    productsmarket: '/api/task/$0/item/market/products', // 临床数据推广产品
    batchUpdateClinicalSettlement: '/api/task/item/$0/market/batch/settlement', // 临床拜访-批量更新结算金额
    batchUpdatePharmacySettlement: '/api/task/item/$0/pharmacy/batch/settlement', // 药店拜访-批量更新结算金额
    batchUpdateChannelSettlement: '/api/task/item/$0/channel/batch/settlement', // 渠道拜访-批量更新结算金额
    marketUpdata: '/api/task/item/market/$0', // 更新结算金额
    deletePart: '/api/task/item/attachment/$0', // 更新结算金额
    subkeyList: '/api/activity/registers/subkey', // 更新结算金额
    qualificationList: '/api/$0/service/qualification', // 服务项目资质列表
    settlementsList: '/api/$0/standard/settlements', // 结算标准列表
    channelCheckTime: '/api/channel/check/time/$0', // 渠道拜访时间
    productsearch: '/api/product/search', // 渠道拜访时间
    settlementsList1: '/api/$0/standard/settlements/$1', // 结算标准列表
    settlementEdit: '/api/$0/standard/settlement/$1', // 结算标准修改
    settlementStandard1: '/api/$0/standard/settlement/$1/$2', // 结算标准修改
    getSettlementStandardDescription: '/api/protocol/$0/standard/settlement/description', // 读取结算标准说明
    taskDownload: '/api/task/download/$0/list', // 附件列表
    wordList: '/api/$0/word', // 敏感词列表
    // validateItem: '/api/$0/task/item/$1/sensitive/task', //检索敏感词（废弃）
    validateTask: '/api/$0/task/$1/sensitive/task', // 检索敏感词
    // sensitiveReport: '/api/$0/task/item/$1/sensitive', //检索报告（废弃）
    sensitiveReportV2: '/api/$0/task/$1/sensitive', // 检索报告
    sensitiveDetail: '/api/task/item/sensitive/$0', // 检索报告详情
    deleteWord: '/api/word/$0', // 删除敏感词
    protocolLogs: '/api/contact/protocol/log', // 预算日志
    protocolLOg: '/api/contact/protocol/$0/budget', // 预算日志
    protocolEdit: '/api/contact/$0/protocol/$1/budget', // 预算修改
    statisticsPro: '/api/productinfos/statistics', // 产品统计
    productinfobudget: '/api/productinfo/$0/budget', // 设置产品预算额度
    statisticalTask: '/api/$0/task/statistical', // 设置任务
    statisticalEdit: '/api/task/statistical/$0', // 设置任务统计
    statisticalLogs: '/api/$0/task/statistical/log', // 操作日志
    statisticalDown: '/api/task/download/statistical/$0', // 统计下载
    statisticalType: '/api/task/statistical/type', // 获取类型
    visitKeyInfo: '/api/promote/task/visit/keyInfo', // 关键信息
    visitFeedBack: '/api/promote/task/visit/feedback', // 客户反馈
    visitKeyInfoDetail: '/api/task/$0/item/market/visits/keyInfo', // 关键信息详情
    visitFeedBackDetail: '/api/task/$0/item/market/visits/feedback', // 客户反馈详情
    editKeyInfo: '/api/visit/task/$0', // 批量修改关键信息
    editFeedBack: '/api/visit/task/$0/feedback', // 批量修改客户反馈
    creatorList: '/api/promote/task/creator', // 创建人列表
    tasksSelectList: '/api/promote/tasks/$0', // 选择任务列表
    checkVisit: '/api/task/item/$0/visit/check/$1', // 检查结果
    visitCriterion: '/api/$0/visit/criterion/$1', // 检查条件
    visitLimitation: '/api/task/item/$0/visit/client/limitation/$1', // 查询每日拜访上线
    salesmanLimitation: '/api/task/item/$0/visit/salesman/limitation/$1', // 业务员拜访上线
    visitSalesman: '/api/task/item/$0/visit/salesman/time/$1', // 每日上限
    clientMatch: '/api/task/item/$0/visit/client/match/$1', // 对比
    visitsSearch: '/api/task/item/$0/visits/$1', // 筛选拜访数据
    visitsCreators: '/api/task/item/$0/visits/creators/$1', // 拜访数据提交人
    deleteVisit: '/api/task/$0/visits', // 删除客户数据
    deleteVisitTerminal: '/api/promote/task/$0/terminal/maintenance',
    deleteterminalVisit: '/api/promote//task/$0/terminal/maintenance', // 删除客户数据
    deleteOnlineVisit: '/api/task/$0/online/visits', // 删除远程拜访
    customerCodes: '/api/customer/code', // 查询客户码
    customerInvitations: '/api/$0/customers/invitations', // 客户邀请列表
    associatedList: '/api/customer/code/associated', // 查询客户码关联客户
    activationCode: '/api/$0/customer/code/activation', // 激活客户码
    activationSuperCode: '/api/$0/customer/super/code/activation', // 激活超级分享码
    actionStatusQuery: '/api/commons/task/cache/task/$0', // 查询任务执行状态
    compliancePage: '/api/compliance/protocol/$0', // 下游查看合规标准
    retrieveList: '/api/task/$0/item/retrieve/$1', // 获取检索列表
    activitySearch: '/api/activity/criterion/$0', // 获取检索列表
    criterionStatus: '/api/activity/criterion/$0/task', // 获取检索状态
    criterionParticipants: '/api/activity/criterion/$0/participants', // 一致性列表
    criterionCost: '/api/activity/criterion/$0/cost', // 合理性列表
    criterionItem: '/api/activity/criterion/$0/item', // 吻合列表
    taskActivity: '/api/$0/task/$1/activity/check', // 任务学术检索结果
    taskActivityStatus: '/api/$0/task/$1/activity/check/status', // 任务学术检索状态
    activityCondition: '/api/$0/$1/activity/check/condition', // 设置学术检索条件
    channelCheckLimit: '/api/channel/check/limitation/$0', // 查询每月渠道拜访上限
    channelCheckResult: '/api/$0/$1/channel/check', // 查询渠道拜访检索结果
    channelTaskList: '/api/$0/$1/channel/check/task', // 渠道拜访检索结果任务
    channelConditions: '/api/$0/$1/channel/check/conditions', // 查询渠道拜访设置条件
    limitationChannel: '/api/channel/check/limitation/$0', // 获取渠道检索每月拜访上限数据
    limitationChannel1: '/api/channel/check/limitation/month/$0', // 获取渠道检索每月拜访上限数据
    channelLimitation: '/api/channel/check/limitation/$0', // 查询每月渠道拜访上限
    channelLimitation1: '/api/channel/check/limitation/month/$0', // 获取渠道检索每月拜访上限数据
    channelAbnormal: '/api/channel/check/abnormal', // 渠道拜访异常数据
    channelAbnormalUser: '/api/channel/check/$0/abnormal/users', // 提交人列表
    getChannelList: '/api/channel/name/check/abnormal', // 查询渠道拜访异常项-每个渠道每月拜访-的渠道列表
    checkHasRoleSeePromoteTask: '/api/promote/task/$0/check/user', // 校验当前用户是否可以查看此任务
    deleteChannelVisit: '/api/channel/check/$0/visit', // 删除客户渠道拜访数据
    activityHold: '/api/$0/task/$1/activity/check/hold', // 频率列表
    activitycheck: '/api/$0/task/$1/activity/check/participants', // 频率列表
    activityHoldDetail: '/api/task/activity/check/hold/$0', // 频率详情
    activityCostDetail: '/api/$0/task/$1/activity/check/cost', // 合理性详情
    activityItemDetail: '/api/task/activity/$0/check/item', // 合理性详情
    activityStatistics: '/api/$0/task/$1/activity/check/Statistics', // 合理性详情
    channelUserCheck: '/api/channel/check/$0/abnormal/users', // 渠道拜访详情提交人
    deleteIem: '/api/task/item/activity/$0', // 删除学术活动
    registersReview: '/api/activity/registers/$0/info/review', // 活动登记信息快审
    taskReview: '/api/$0/task/item/activity/$1/review', // 活动登记信息快审
    itemActiveName: '/api/$0/task/$1/item/activity/name', // 活动登记信息快审
    HomeCustomer: '/api/statistics/$0/customer', // 首页地图数据
    HomePromoteTask: '/api/statistics/$0/promoteTask', // 首页饼形图数据
    submitterList: '/api/statistics/$0/submitter', // 首页提交对象
    HomeProduct: '/api/contact/$0/protocol/product', // 首页产品
    HomeStatistics: '/api/statistics/partyb/$0/item', // 首页产品
    HomeStaff: '/api/statistics/$0/staff', // 首页成员
    Homebudget: '/api/statistics/$0/$1/budget', // 首页占比饼形图
    HomeSettlement: '/api/statistics/$0/$1/settlement', // 首页柱形图
    HomeDate: '/api/statistics/$0/$1/task/submit/date', // 开始时间结束时间
    customersDepart: '/api/$0/customers/departments', // 客户科室
    customersCities: '/api/$0/customers/cities', // 客户城市
    // customersHos: '/api/$0/customers/hospitals', //客户医院
    customersHosV2: '/api/customers/hospitals', // 客户医院
    // customersHosNew: '/api/$0/customer/hospital/new', //新的客户医院接口
    partyaInfo: '/api/contact/partya/$0/info', // 所有签约对象
    visitItemTip: '/api/task/item/$0/visit/product/$1', // 客户科室匹配提示语
    protocolProduct: '/api/contact/$0/protocol/product', // 所有签约对象产品
    TemplateIndex: '/api/task/download/index/template', // 模版首页
    TemplateMenu: '/api/task/download/$0/$1/collection/menu', // 合集下载菜单
    TemplatePreview: '/api/task/download/$0/index/template/preview/url', // 模版首页预览
    refusedList: '/api/refused/reason/$0', // 拒绝理由
    guideModal: '/api/$0/guide', // 新手指南
    collectType: '/api/info/collect/promote/type', // 推广项目类型
    getCommonField: '/api/info/collect/template/$0/common/field', // 获取常用模版字段
    addTemplate: '/api/info/collect/template', // 新增模版
    templateList: '/api/info/collect/$0/template', // 模版列表
    templateUserList: '/api/info/collect/$0/template/user', // 模版创建人列表
    getCollect: '/api/info/collect/template/$0', // 获取模版
    CollectList: '/api/info/collect', // 获取登记列表
    collectRegisterList: '/api/info/collect/$0/register', // 登记信息列表
    submitterData: '/api/info/collect/submitter/$0', // 提交人列表
    collectDraft: '/api/info/collect/$0/register/draft', // 登记信息草稿箱列表
    templateAll: '/api/info/collect/$0/template/$1', // 获取下拉模版信息
    AddRegister: '/api/info/collect/register', // 新建信息登记
    registerDetail: '/api/info/collect/register/$0', // 信息登记详情
    competitionEdit: '/api/info/collect/register/$0', // 竞品信息编辑 (这里服务端给的接口描述错了，其实就是信息登记详情，用于编辑页面)
    DownloadRes: '/api/info/collect/register/$0/download', // 下载信息登记详情
    taskItemInfo: '/api/task/item/$0/info', // 获取推广任务终端调研列表
    competitionFeedbackInfo: '/api/task/item/$0/info/feedback', // 获取推广任务竞品反馈列表
    taskItemInfoList: '/api/task/$0/item/info', // 获取推广任务选择终端调研列表
    deleteInfoList: '/api/task/item/$0/info', // 删除信息
    upDataInfoMoney: '/api/task/item/$0/info/$1', // 信息更新金额
    batchUpDataInfoMoney_v2: '/api/v2/task/item/$0/info/batch', // 批量修改结算金额
    getMaxSettlementForBatch: '/api/task/item/$0/settlement/maximum/batch', // 批量修改数据时显示最大结算金额
    templateExist: '/api/info/collect/template/number/exist', // 检查编号是否存在
    templateCopy: '/api/info/collect/template/copy', // 复制代码创建模版
    terminalInfo: '/api/promotetask/$0/terminal/research/check', // 信息收集检索结果
    readTerminalCheckStatus: '/api/promotetask/$0/terminal/research/check/status', // 查询信息收集检索结果状态V2
    terminalName: '/api/terminal/research/check/$0/name', // 终端信息唯一性
    terminalNameDetail: '/api/terminal/research/check/$0/$1/name/info', // 终端信息唯一性详情
    terminalStatistics: '/api/terminal/research/check/$0/statistics', // 终端信息完整性
    terminalStatisticsItem: '/api/terminal/research/check/$0/item', // 终端异常项列表查看详情接口
    terminalStatisticsItemDelete: '/api/terminal/research/$0/check/$1/statistics', // 终端异常项列表详情删除
    taskItemInfoDelete: 'api/task/item/$0/info/check/$1', // 删除异常详情
    terminalCondition: '/api/terminal/research/check/$0/condition', // 添加条件字段
    getWantInviteCustomers: '/api/customers/forinvite', // 获取要添加的客户列表信息
    participantsEX: '/api/activity/participants/$0', // 编码导入客户
    registersEx: '/api/activity/registers/code/exist/$0', // 编码状态判断
    imageCheck: '/api/$0/task/$1/activity/check/image', // 图片唯一性
    taskCheck: '/api/promote/task/$0/check', // 发送推广任务的检查
    feedbackStart: '/api/info/feedback/check/$0/$1', // 竞品信息开始检索
    feedbackCheckStatus: '/api/promotetask/$0/info/feedback/check/status', // 竞品信息检索状态
    feedbackCheck: '/api/info/feedback/check/$0/$1', // 竞品信息检索结果
    feedbackCheckList: '/api/info/feedback/check/$0/name', // 竞品信息结果分页
    feedbackCheckDelete: '/api/info/feedback/$0/check/$1', // 竞品信息结果删除
    feedbackCheckDetailList: '/api/info/feedback/check/$0/name/info', // 竞品信息详情结果分页
    taskCheckSettlement: '/api/promote/task/$0/check/settlement', // 发送推广任务的检查
    pharmacysList: '/api/$0/pharmacys', // 药店信息列表
    pharmacyUpload: '/api/pharmacy/upload/$0', // 药店导入
    pharmacyDown: '/api/pharmacy/download', // 药店模版下载
    pharmacyTask: '/api/pharmacy/task/$0', // 查询上传进度
    clerksList: '/api/pharmacy/$0/clerks', // 店员列表
    clerksPage: '/api/pharmacy/clerks', // 分页查询公司的药店客户列表
    pharmacyDetail: '/api/pharmacy/$0', // 药店信息详情
    pharmacysAssign: '/api/$0/pharmacy/assign/members', // 药店指派列表
    pharmacysAssignList: '/api/$0/pharmacy/assign/pharmacys', // 药店药店指派列表
    pharmacyAss: '/api/pharmacy/assign', // 药店指派
    pharmacysOwn: '/api/pharmacy/assign/own/$0/pharmacys', // 已经指派药店列表
    pharmacysDelete: '/api/pharmacy/assign/$0/$1', // 取消指派药店
    assignList: '/api/pharmacy/$0/assign', // 客情信息列表
    pharmacyVisit: '/api/visit/pharmacy/$0', // 药店拜访列表
    pharmacyProduct: '/api/visit/pharmacy/$0/product', // 药店拜访列表推广产品列表
    pharmacySubmitter: '/api/visit/pharmacy/submitter/$0', // 药店拜访列表提交人列表
    pharmacyBatch: '/api/visit/pharmacy/$0/batch', // 药店拜访批量删除
    pharmacysOwnList: '/api/$0/own/pharmacys', // 药店我的客户列表
    pharmacysBase: '/api/pharmacys/base', // 药店我的客户列表
    pharmacyPosts: '/api/pharmacy/posts', // 职务列表
    pharmacyAdd: '/api/pharmacy', // 添加药店
    deletePharmacy: '/api/pharmacy/assign/$0', // 删除我的药店
    pharmacyItems: '/api/task/item/$0/pharmacy', // 药店拜访列表/
    pharmacyVisitDistrict: '/api/task/$0/pharmacy/extra/districtIds', // 药店拜访区域校验
    channelVisitDistrict: '/api/task/$0/channel/extra/districtIds', // 渠道拜访区域校验
    pharmacyMoney: '/api/task/item/pharmacy/$0', // 药店拜访列表更新结算金额
    pharmacyProductList: '/api/task/$0/item/pharmacy/products', // 药店拜访推广产品
    pharmacyUser: '/api/task/$0/item/pharmacy/users', // 药店拜访提交人
    pharmacyDownPart: '/api/task/download/$0/pharmacy/summary', // 药店拜访清单下载
    taskPharmacy: '/api/visit/task/$0/pharmacy', // 药店拜访预览列表
    taskPharmacyVisitList: '/api/task/$0/item/pharmacy', // 药店拜访预览列表
    visitDaily: '/api/visit/pharmacy/$0/daily', // 药店每日拜访
    pharmacyproduct: '/api/visit/pharmacy/$0/product', // 药店推广产品
    pharmacyInfo: '/api/visit/pharmacy/product/info', // 药店推广产品
    assignsDelete: '/api/pharmacy/$0/assigns', // 批量移除指派
    pharmacyDownLoad: '/api/visit/pharmacy/download/$0/$1', // 批量移除指派
    channelDown: '/api/visit/channel/download/$0/$1', // 渠道清单下载
    pharmacysSingle: '/api/pharmacy/assign/$0/pharmacys', // 单个指派列表
    AddVisit: '/api/visit/pharmacy', // 新增药店拜访
    promoteProductKeyInfo: '/api/promote/product/keyInfo/$0/$1', // 药店拜访某一产品的关键信息与快捷信息
    updatePharmacyVisitDetail: '/api/visit/pharmacy', // 更新药店拜访详情

    AddClerk: '/api/pharmacy/$0/clerk', // 新增店员
    editClerk: '/api/pharmacy/clerk/$0', // 編輯店员
    pharmacyItemDelete: '/api/task/item/pharmacy/$0', // 移除数据
    pharmacyVisitDetail: '/api/task/item/pharmacy/$0/visits', // 拜访药店详情列表
    pharmacyItemVisits: '/api/task/item/pharmacy/$0/visit', // 拜访药店详情批量移除
    productPromoteList: '/api/visit/pharmacy/$0/productPromote', // 药店拜访产品
    downloadpharmacy: '/api/task/download/pharmacy/visit/$0', // 药店拜访产品
    DataRepeat: '/api/task/item/$0/visit/repeat/$1', // 数据重复性检索详情
    pharmacySalesman: '/api/pharmacy/check/salesman/$0', // 药店拜访每日拜访上线
    // visitDepartment: '/api/task/item/$0/visit/department/match/$1', // 客户科室匹配分页（重复且废弃）
    pharmacyLimitation: '/api/pharmacy/check/limitation/$0', // 药店拜访每月拜访上线
    pharmacyPosition: '/api/pharmacy/check/visit/location/$0', // 药店拜访定位异常详情
    pharmacyPositionCompare: '/api/pharmacy/check/visit/location/$0/compare', // 药店拜访定位有效性-对比定位
    pharmacyInterval: '/api/pharmacy/check/time/interval/$0', // 药店拜访每日拜访时间间隔
    pharmacyTime: '/api/pharmacy/check/time/$0', // 药店拜访每日拜访时间
    pharmacyCity: '/api/pharmacy/check/city/$0', // 药店拜访每日拜访城市
    pharmacyVisitIntegrality: 'api/pharmacy/check/visit/location/{checkId}/criterion', // 药店拜访-终端位置信息完整性
    pharmacyCheck: '/api/$0/$1/pharmacy/check', // 药店拜访检索结果查询
    pharmacyTaskList: '/api/$0/$1/pharmacy/check/task', // 药店拜访检索结果任务
    pharmacyAbnormal: '/api/pharmacy/check/abnormal', // 检索详情
    abnormalUser: '/api/pharmacy/check/abnormal/users', // 药店检索详情提交人列表
    pharmacyConditions: '/api/$0/$1/pharmacy/check/conditions', // 药店检索详情设置条件
    pharmacyDeVisit: '/api/pharmacy/check/$0/visit', // 药店检索详情删除
    pharmacyUserDelete: '/api/pharmacy/check/$0/abnormal/users', // 药店检索详情删除
    promoteLocal: '/api/promote/task/company/$0/local', // 本地管理库分页
    promoteLocalAdd: '/api/promote/task/company/local', // 本地管理库添加
    promoteLocalDel: '/api/promote/task/company/local/$0', // 本地管理库删除
    questionnaireAnomalousCondition: '/api/questionnaire/check/$0/condition', // 问卷调研异常项
    questionnaireCheckResult: '/api/questionnaire/check/$0/$1', // 问卷调研异常项检索结果
    questionnaireCheckStatus: '/api/questionnaire/check/status/$0/$1', // 查询问卷调研检索状态
    questionnaireCheckQuote: '/api/questionnaire/check/$0/title/quote', // 问卷题目完整性分页
    selfList: '/api/$0/self/support/list/down', // 检索选项
    pharmacyListDownSelect: '/api/$0/self/support/pharmacy/check/visit/location/scope/list/down', // 药店定位对象选项
    questionnaireCheckSimilarity: '/api/questionnaire/check/$0/summary/duplicate', // 问卷总结相似性结果分页
    questionnaireCheckTemplate: '/api/questionnaire/check/$0/quote/template', // 问卷检索模版正确性
    questionnaireCheckTemplatePatient: '/api/questionnaire/patient/check/$0/quote', // 问卷（患者）检索模版正确性
    questionnaireCheckTitle: '/api/{taskId}/questionnaire/check/assign/title/research', // 问卷指定题目专项检索
    questionnaireCheckTitleDetail: '/api/questionnaire/check/$0/assign/title/research/info', // 问卷指定题目专项检索详情
    questionnaireCheckTitleDetailAnswerInfo: '/api/$0/assign/title/research/answerInfo', // 问卷指定题目专项检详情 答题详情
    questionnaireCheckTitleDetailResearchers: '/api/$0/assign/title/research/researchers', // 问卷指定题目专项检详情 调研人员
    questionnaireSummaryDuplicateInfo: '/api/questionnaire/check/$0/summary/duplicate/info', // 问卷总结相似性详情
    questionnaireSummaryInfo: '/api/questionnaire/check/$0/summary/info', // 问卷总结相似性详情对比结果
    questionnaireQuoteInfo: '/api/questionnaire/check/$0/$1/title/quote/info', // 问卷题目完整性题目引用详情分页
    taskLocal: '/api/promote/task/$0/local', // 存档推广任务
    reportCheck: '/api/task/$0/item/report/check/$1', // 获取咨询报告
    pharmacyOrien: '/api/pharmacy/check/orientation/$0', // 药店定位详情
    reportCriterion: '/api/$0/report/criterion/$1', // 获取咨询报告条件
    reportSimilarity: '/api/task/$0/item/report/check/$1/similarity', // 获取咨询报告相似性列表
    reportSimilarityDetail: '/api/task/$0/item/report/check/$1/similarity/$2', // 获取咨询报告相似性详情
    registerFlow: '/api/info/collect/register/flow', // 流向登记新建
    collectUpload: '/api/info/collect/upload/$0', // 流向登记excel上传
    collectDownload: '/api/info/collect/download/$0', // 流向登记excel模版下载
    editFlow: '/api/info/collect/register/flow/$0', // 编辑流向编辑
    membershipWx: '/api/$0/membership/order/wx', // 微信下单
    membershipPaying: '/api/$0/membership/paying', // 查询订单支付状态
    membershipDelete: '/api/membership/$0', // 取消订单
    membershipStatus: '/api/membership/$0/status', // 查询订单状态
    membershipDown: '/api/membership/$0/download', // 付款信息下载
    ordersList: '/api/$0/orders', // 付款记录
    qrcodeShop: '/api/membership/$0/qrcode', // 生成二维码
    orderBusiness: '/api/$0/membership/order/business', // 对公转账下单
    couponsInfo: '/api/coupons/$0', // 查询优惠券信息
    orderDetail: '/api/membership/order/$0', // 订单详情接口
    upgradeSuper: '/api/company/$0/upgrade', // 公司升级超级会员
    subcontractList: '/api/promote/task/subcontract/$0', // 分包任务列表
    isWithinAreaTask: '/api/promote/task/subcontract/$0/isWithinArea', // 分包查看区域范围
    isWithinDateTask: '/api/promote/task/subcontract/$0/isWithinDate', // 分包查看时间范围
    subcontractItems: '/api/task/$0/item/subcontract', // 分包查看服务项目
    subcontractTask: '/api/promote/task/subcontract/$0/$1', // 查看分包任务甲方乙方
    addSubcontract: '/api/promote/task/subcontract', // 甲方添加分包任务
    subcontractBudgetLog: ' /api/contact/$0/budget/log', // 查看分包商的预算日志
    subcontractBudgetInfo: '/api/contact/$0/budget/info', // 查看分包商的预算与结余
    updateSubcontractBudget: '/api/contact/$0/budget', // 更新分包商的预算
    subcontractorList: '/api/contact/partya/$0', // 上游查看签约信息列表
    surveyInfo: '/api/promote/task/subcontract/$0/overview', // 发布的分包概况信息
    upload: '/api/contact/$0', // 上传协议
    subcontractDetail: '/api/promote/task/subcontract/$0/info', // 分包详情
    subcontractOver: '/api/promote/task/subcontract/$0/overview', // 分包概况
    subcontractServers: '/api/promote/task/subcontract/$0/items/$1/detail', // 分包项目
    automaticAdd: '/api/promote/task/subcontract/$0/auto', // 自动添加
    synchronization: '/api/promote/task/subcontract/$0/Synchronous', // 同步分包任务
    switchChange: '/api/task/$0/item/$1', // 分包更新服务项目自动更新开关
    subcontractorSurvey: '/api/contact/detail/$0', // 分包商概况
    checkServesItem: '/api/task/$0/item/$1/subcontract', // 分包商概况
    agreementDelete: '/api/contact/$0/agreement', // 删除协议
    HosVisit: '/api/task/item/$0/visit/frequency/$1', // 临床拜访4小时拜访列表
    limitationTime: '/api/task/item/$0/visit/hospital/limitation/$1', // 每日医院拜访上线
    departmentIs: '/api/$0/task/$1/activity/check/department', // 科室人员合理性
    includeTargetFeedback: '/api/$0/task/$1/activity/check/includeTargetFeedback', // 内容完整性-目的与反馈
    activitySignList: '/api/activity/criterion/$0/include/sign', // 单个活动 内容完整性-签到表 查询
    includeSignList: '/api/task/$0/activity/check/includeSignList', // 推广任务 内容完整性-签到
    informationSub: '/api/$0/promote/task/subcontract/information', // 查看分包状态变更
    // taskDepartmentMatch: '/api/task/item/$0/visit/department/match/$1', //临床拜访客户科室匹配分页（废弃，服务端能取到的就不用前端传，前端开发可以要求服务端接口写得更简洁）
    taskDepartmentMatchV2: '/api/task/item/$0/visit/department/match', // 临床拜访客户科室匹配分页
    taskVisitProduct: '/api/task/item/$0/visit/product/$1', // 推广产品科室
    questionTask: '/api/task/$0/question?serviceItemId=20', // 问卷总结
    questionTaskPatient: '/api/task/$0/question?serviceItemId=21', // 问卷总结（患者）
    QuestionnaireList: '/api/$0/questionnaires', // 查询问卷列表
    taskItemQuestionnaires: '/api/task/item/questionnaires', // 查询推广任务问卷列表
    subcontractQuestionnaireList: '/api/subcontract/$0/questionnaires', // 分包引用问卷列表
    updateDefaultQuestion: '/api/question/default/update/$0', // 更新常用题目
    checkQuestionnarePublicationStatus: '/api/questionnaire/$0/publication/check',
    QuestionnaireCreator: '/api/$0/questionnaire/creators', // 问卷列表-创建人
    QuestionnaireSurvey: '/api/questionnaire/$0', // 查看问卷详情
    deleteQuestionnaireTemplate: 'api/questionnaire/$0/template/$1', // 删除问卷模版
    PublishingProcess: '/api/questionnaire/$0/plan', // 查看问卷详情-发布历程
    DeleteQuestionnaire: '/api/questionnaire/$0', // 删除问卷
    getQuestionnaireIdByNo: '/api/questionnaire/get/id/$0', // 通过编号获取问卷id
    AnswerSheetList: '/api/questionnaire/$0/sheet', // 获取答卷列表
    answerSummary: '/api/questionnaire/$0/analyse/$1/user', // 用户答卷分析查看汇总
    answerSummaryDetail: '/api/questionnaire/$0/question/$1/user', // 用户答卷分析查看问题关联答卷
    previewQuesTemplate: '/api/questionnaire/$0/imgs', // 问卷预览
    previewQuesTemplate1: '/api/questionnaire/imgs', // 问卷预览
    questionDefault: '/api/questionnaire/question/default/$0', // 获取默认问题
    GetAnswerSheet: '/api/questionnaire/sheet/$0', // 获取答卷
    DeleteAnswerSheet: '/api/questionnaire/sheet/$0', // 删除答卷
    AnswerSheetPdf: '/api/questionnaire/sheet/$0/pdf', // 答卷pdf
    questionSummaryEdit: '/api/task/item/question/$0/summary', // 修改问卷总结
    AnswerSheetAnalysis: '/api/questionnaire/$0/analysis', // 答卷分析
    CheckAnswerSheet: '/api/questionnaire/$0/question/$1', // 答卷分析查看问题关联答卷
    PublishQuestionnaire: '/api/questionnaire/$0/publication', // 发布/取消发布问卷
    createQues: '/api/$0/questionnaire', // 创建问卷
    conclusionUpdate: '/api/questionnaire/$0/conclusion', // 创建问卷
    questionItemList: '/api/task/item/$0/question', // 推广任务问卷列表
    fillingQues: '/api/questionnaire/$0/filling', // 答题
    questionPrice: '/api/task/item/question/$0', // 对单个问卷进行查询或修改、删除
    questionPricePatient: '/api/task/anomalous/$0/questionnaire/patient/$1', // 对单个问卷（患者）进行查询或修改、删除
    answerDetail: '/api/task/item/question/$0/answer', // 问卷详情
    QRdown: '/api/questionnaire/qRCode', // 获取二维码下载
    SheetPdf: '/api/questionnaire/$0/sheet/pdf', // 答卷详情下载
    wxSign: '/api/pay/wx/signature', // 答卷详情下载
    sheetImgs: '/api/questionnaire/sheet/$0/imgs', // 生成答卷预览
    checkSheetQuestionnaire: '/api/questionnaire/sheet/$0/check', // 检查问题是否被访问过
    offlineUpload: '/api/task/item/$0/question/offline', // 綫下上傳問卷
    questionSummary: '/api/task/item/question/$0/summary', // 更新问卷总结
    criterionDepart: '/api/activity/criterion/$0/department', // 更新问卷总结
    questionnairePack: '/api/questionnaire/analyse/$0/auto/packing', // 问卷调研-自动封装
    downLoadPharTemplate: '/api/hospital/download', // 客户管理医院下载excel导入模板
    positionSetting: '/api/visit/company/location', // 定位设置
    pharmacyPositionSetting: '/api/company/$0/pharmacy/visit/location', // 药店拜访定位设置
    importSetting: '/api/visit/batch/import/switch', // 批量导入开关
    bulkImportSetting: '/api/visit/batch/import/switch', // 批量导入开关
    recommendSetting: '/api/recommend/config/web', // 系统推荐设置
    questionnaireQr: '/api/questionnaire/plan/$0', // 传递签名图片
    signDownload: '/api/activity/$0/$1/sign/download/excel', // 参会信息导出
    promoteProducts: '/api/$0/promote/products', // 公司产品
    channelVisitTaskDown: '/api/task/download/channel/visit/$0', // 渠道清单下载-推广任务
    PrincipalContact: '/api/contact/Principal/$0', // 委托方列表
    productsNew: '/api/$0/products', // 产品列表新
    verifyTaskVersionChange: '/api/task/assign/draft/$0/check/protocol', // 校验协议版本变更
    informationReleaseList: '/api/$0/information/receive', // 发布资讯列表
    productsUsers: '/api/products/$0/users', // 产品指派列表
    productsUsersDetail: '/api/products/$0/user/$1', // 产品指派详情列表
    productAssign: '/api/product/assign/$0', // 产品批量指派
    productsProDetail: '/api/$0/promote/products/$1', // 产品批量指派
    questionnaireAss: '/api/questionnaire/plan/$0/associated/task', // 查询是否关联
    assignDelete: '/api/products/assign', // 刪除指派
    PrincipalUser: '/api/contact/Principal/$0/user/$1', // 刪除指派
    customerGetCode: '/api/$0/customer/code', // 获取客户码
    customerGetCodeV2: '/api/$0/customer/code/v2', // 个人版自助生成客户码及客户数量
    getUserLastCreateCodeInfo: '/api/customer/code/last/one', // 查看最近一次创建的分享码详情
    creationCode: '/api/$0/customer/code/creation', // 查看客户码创建次数
    isSuperCode: '/api/customer/code/isSuper', // 是否是超级分享码
    channelsList: '/api/$0/channels', // 渠道列表
    channelsClerk: '/api/channels/$0/clerks', // 渠道客户列表
    channelsClerkPage: '/api/channels/clerks', // 分页查询公司的渠道客户列表
    channelsDown: '/api/channels/download', // 渠道模版下载
    channelsExport: '/api/channels/upload/$0', // 渠道批量导入
    channelsTask: '/api/channels/task/$0', // 查询导入进度
    cooperationChances: '/api/channels/cooperationChances', // 经营特点列表
    paybackPeriods: '/api/channels/paybackPeriods', // 回款周期列表
    operateFeatures: '/api/channels/operateFeatures', // 合作机会列表
    addChannels: '/api/channels', // 新增渠道
    channelsAssign: '/api/$0/channels/assign/members', // 渠道指派列表
    channels: '/api/channels/assign', // 单个指派列表
    channelsSingle: '/api/$0/channels/assign/$1', // 单个指派列表
    channelsDetail: '/api/channels/$0', // 渠道详情
    clerksListChannel: '/api/channels/$0/clerks', // 渠道成员列表
    deleteChannelHas: '/api/channels/assign/$0/$1', // 移除成员指派的渠道
    deleteChannelSelf: '/api/channels/assign/$0/$1/self', // 移除成员指派的渠道
    channelPosts: '/api/channel/posts', // 渠道职务
    channelDaily: '/api/visit/channel/$0/daily', // 渠道拜访每日列表
    channelsDelete: '/api/channels/assign/$0', // 渠道拜访每日列表
    channelsEditClerk: '/api/channels/clerks/$0', // 渠道编辑成员
    channelVisit: '/api/visit/channel/$0', // 拜访渠道列表
    productPromoteChannel: '/api/visit/channel/$0/productPromote', // 拜访渠道产品列表
    channelSubmitter: '/api/visit/channel/submitter/$0', // 拜访渠道提交人列表
    channelItems: '/api/visit/channel/item', // 拜访渠道项目列表
    addVisitChannel: '/api/visit/channel', // 新增渠道拜访
    channelVisitEditDetail: '/api/visit/channel/detail/$0', // 编辑渠道拜访-获取详情数据
    channelVisitIntegrality: '/api/channel/check/location/criterion/$0', // 药店拜访-终端位置信息完整性
    checkVisitTask: '/api/task/item/$0/visit/check/$1/task', // 异常项设置开关
    DeleteVisitChannel: '/api/visit/channel/$0', // 删除渠道拜访
    batchVisitChannel: '/api/visit/channel/$0/batch', // 删除渠道拜访批量
    channelsMine: '/api/$0/channels/assign', // 我的渠道客户列表
    channelTask: '/api/task/item/$0/channel', // 渠道拜访列表-推广任务
    channelProducts: '/api/task/$0/item/channel/products', // 渠道产品列表-推广任务
    channelUsers: '/api/task/$0/item/channel/users', // 渠道提交人列表-推广任务
    terminalResearch: 'api/down/promotetask/$0/terminal/research/check', // 渠道提交人列表-推广任务
    channelTaskItems: '/api/task/$0/item/channel', // 渠道记录预览-推广任务
    channelEditMoeny: '/api/task/item/channel/$0', // 渠道更改结算金额-推广任务
    channelVisitDetail: '/api/task/item/channel/$0/visits', // 渠道详情列表-推广任务
    batchDeleteChannelVisit: '/api/task/item/channel/$0/visit', // 批量删除渠道拜访
    standardReturn: '/api/compliance/$0/standard/return/$1', // 下游获取行为准则
    getStandardStatus: '/api/compliance/$0/standard/return/$1/status', // 下游是否获取行为准则
    complianceReturn: '/api/compliance/$0/standard/return/$1/$2 ', // 下游是否获取行为准则
    channelSummary: '/api/task/download/$0/channel/summary', // 渠道汇总表下载-推广任务
    waitSignContact: '/api/contact/partyb/$0/protocols/waitSign/count', // 下游等待签约数量
    anomalousItems: '/api/$0/subcontract/anomalous/$1/items', // 分包异常项检索条件
    subcontractCheckPharmacy: '/api/$0/subcontract/pharmacy/check/conditions', // 分包异常项检索条件设置药店
    subcontractCheckActivity: '/api/$0/subcontract/activity/check/condition', // 分包异常项检索条件设置学术活动
    subcontractCheckVisit: '/api/$0/visit/criterion/subcontract', // 分包异常项检索条件设置临床
    subcontractCheckResearch: '/api/subcontract/terminal/research/check/$0/condition', // 分包异常项检索条件设置终端调研
    beforeSubcontactBudgetCheck: '/api/promote/task/subcontract/$0/contact/budget/check', // 通过并同步分包任务前 校验该分包任务的结算总计是否大于结余
    // calcExceptBudget: '/api/task/item/$0/calc/budget', //计算预计预算金额
    calcExceptBudget2: '/api/task/item/calc/budget', // 新的计算预算金额接口（代替上面的）
    isHadLocation: '/api/task/item/$0/visit/isHadLocation/$1', // 临床拜访定位检索详情列表
    visitImgDupCheckList: '/api/task/$0/visit/checkresult/imgdup', // 临床拜访图片相似性检索结果-列表
    onLineVisitResult: '/api/task/item/$0/online/visit/$1', // 远程拜访实名率结果
    onLineVisitAbnormal: '/api/task/item/$0/abnormal/online/visits', // 远程拜访异常数据详情
    includeScenePhotos: '/api/$0/task/$1/activity/check/includeScenePhotos', // 内容完整性-现场照片
    includeActivityItems: '/api/$0/task/$1/activity/check/includeActivityItems', // 内容完整性-活动子项
    activityInclude: '/api/activity/criterion/$0/includeActivityItems', // 内容完整性-活动子项 单个活动检索
    includeScenePhotosItems: '/api/activity/criterion/$0/includeScenePhotos', // 内容完整性-活动子项 单个活动检索
    allUnNoticed: '/api/message/allUnNoticed/list', // 内容完整性-活动子项 单个活动检索
    operationMessage: '/api/message/operation/$0/status/$1', // 消息已读
    allListMessage: '/api/message/allList/$0', // 第一次进入邀请消息

    // 投标
    biddingList: '/api/$0/$1/tenders', // 投标列表
    bidDelete: '/api/tenders/$0', // 删除
    bidChangeStatus: '/api/tenders/$0/resend', // 变更状态：重新发送
    bidReceiveCompany: '/api/$0/tenders/receiver', // 获取接收方企业
    companyNameCheck: '/api/company/name/check', // 检验公司名称
    companyCodeCheck: '/api/company/check', // 检验公司ID
    getProducts: '/api/tenders/receiver/$0/products', // 获取乙方业务线产品
    getCountry: '/api/tenders/productLine/$0/areas', // 获取业务线区域
    bidSend: '/api/$0/tenders/send', // 发送投标
    needComplete: '/api/company/$0/info/need/complete', // 检查是否需要完善
    doneComplete: '/api/company/$0/info/done/complete', // 完善信息
    completeCheck: '/api/company/$0/businesinfo/check/complete', // 检验是否需要完善信息
    ruleConfig: '/api/approval/rule/config/detail/$0/3', // 检验上游是否开启计划审批
    getProductLineID: '/api/company/productionLine/id', // 根据企业id和产品获取业务线id
    getProviceList: '/api/productionLines/$0/provinces', // 业务线可选省份
    getCitiesList: '/api/productionLines/$0/cities', // 业务线可选城市
    getRegionList: '/api/productionLines/$0/districts', // 业务线可选区县

    // 任务审批
    promoteAudit: '/api/promote/plan/$0/audit', // 检验上游是否开启计划审批
    settlementInfoItems: '/api/standard/settlement/info/items', // 终端调研分级可选项目
    taskAssignAudit: '/api/promote/plan/$0/audit/assignType', // 任务指派后的任务审核

    ruleStatusCompany: '/api/approval/rule/config/detail/$0/$1/status', // 查询上游规则开启
    ruleStatusDetail: '/api/approval/rule/config/detail/$0/$1', // 查询上游规则详情
    ruleStatus: '/api/approval/rule/config/$0/supplement/$1/status', // 查询上游规则开启
    effectTask: '/api/promote/task/$0/$1/effect/summary', // 修改开展情况和总结
    effectTask2: 'api/promote/task/item/$0/effect/summary', // 修改开展情况和总结
    effectSummary: '/api/promote/task/$0/effect/summary/list', // 获取所有开展列表
    effectExpect: '/api/promote/task/$0/effect/expect/list', // 获取所有开展列表
    settlementMaximum: '/api/task/item/$0/settlement/maximum', // 获取预算提示
    settlementDefalut: '/api/task/item/$0/settlement', // 编辑或者添加数据时显示最大结算金额
    settlementRule: '/api/task/$0/settlement/rule', // 检查上游开启规则
    settlementRatio: '/api/task/$0/settlement/proportion/rule', // 检查上游是否开启结算项目费用占比(按类型统计)规则
    budgetRatio: '/api/task/$0/budget/proportion/rule', // 检查上游是否开启预算项目费用占比(按类型统计)规则
    settlementType: '/api/task/item/$0/settlement/info', // 获取预算提示
    settlementStatsSave: '/api/settlement/stats/2', // 结算凭证编号生成
    taskSettlementStatsInfo: '/api/settlement/stats/$0/info', // 推广任务结算凭证
    settlementStatsProtocols: '/api/settlement/stats/$0/protocols', // 引用协议列表
    settlementStatsTasks: '/api/settlement/stats/$0/tasks', // 引用任务列表
    deleteSettlementStats: '/api/settlement/stats/$0', // 删除结算统计
    settlementStatsCheck: '/api/settlement/stats/$0/check', // 生成结算凭证验证
    settlementZipExport: '/api/settlement/stats/$0/export', // 结算Zip导出
    settlementFadadaSign: '/api/settlement/$0/$1/fadada/sign', // 结算签章
    settlementStatsRule: '/api/settlement/stats/$0/rule', // 结算凭证规则

    settlementAnalysis: '/api/task/$0/settlement/analysis', // 推广任务结算分析
    budgetAnalysis: '/api/task/$0/budget/analysis', // 推广任务预算分析
    effectDown: '/api/task/download/$0/effect/expect', // 申请表下载
    summaryDown: '/api/task/download/$0/effect/summary', // 总结表下载

    taskVisitTime: '/api/task/item/$0/visit/one/time/$1', // 单次拜访时长
    pharmacyVisitTime: '/api/pharmacy/check/one/time/$0', // 药店单次拜访时长
    channelVisitTime: '/api/channel/check/one/time/$0', // 渠道单次拜访时长
    visitNotes: '/api/channel/check/include/visit/summary/$0', // 内容完整性-拜访小记
    visitsOptions: '/api/task/item/$0/visits/option', // 拜访数据筛选项
    onlineVisitOptions: '/api/task/item/$0/online/visits/option', // 远程拜访数据筛选项

    repetitiveActive: '/api/activity/criterion/$0/repetitive/participant', // 活动同名参与人
    repetitiveActivity: '/api/activity/criterion/$0/repetitive', // 活动同名异常项
    repetitiveCheck: '/api/$0/task/$1/activity/check/repetitive', // 参会人重复性
    repetitiveActivePlan: '/api/$0/task/$1/activity/$2/check/repetitive/participant', // 活动同名参与人推广任务
    criterionTragetFeedback: '/api/activity/criterion/$0/include/target/feedback', // 活动目的与反馈异常详情
    criterionSyncPlan: '/api/company/$0/$1/criterion/sync', // 任务查看是否开启同步
    criterionCheckPlan: '/api/company/$0/$1/criterion/sync', // 开关

    activityCheckCondition: '/api/$0/$1/activity/check/condition/$2', // 学术活动设置
    checkCostStand: '/api/task/item/$0/activity/check', // 添加活动费用标准校验
    activityFrequency: '/api/activity/criterion/$0/attend/frequency', // 学术活动参会频率合理性
    activityParticipant: '/api/activity/criterion/$0/attend/frequency/participant', // 学术活动参会频率合理性参会人信息
    activityParticipantPlan: '/api/$0/task/$1/activity/check/attend/frequency', // 学术活动参会频率合理性推广任务
    activityFrequencyPlan: '/api/$0/task/$1/activity/$2/check/attend/frequency/participant', // 学术活动参会频率合理性参会人信息推广任务

    // 首页待办事项
    todoList: '/api/company/$0/todo', // 查询用户待办事项
    ignoreTodoList: '/api/company/$0/todo/item/$1', // 忽略待办事项
    getprocessTask: '/api/$0/task/process', // 查询进展中推广任务任务
    getTaskProjects: '/api/task/$0/process', // 查看推广任务进程
    getAssignment: '/api/$0/task/assignment', // 查询所有已经指派给我的推广任务
    getAssignProjects: '/api/task/$0/assignment/user', // 推广任务查看指派给某个人
    getAssSummary: '/api/$0/task/assignment/summary', // 用户首页汇总--快捷入口
    getProSummary: '/api/$0/task/process/summary', // 用户首页汇总--进度

    /** 异常项目检索结果 */
    reviewSwitch: '/api/criterion/review/$0/switch', // 修改异常项开关
    reviewVisitChannel: '/api/criterion/review/$0/channel/visit', // 渠道拜访
    reviewVisitHospital: '/api/criterion/review/$0/hospital/visit', // 临床拜访
    reviewVisitPharmacy: '/api/criterion/review/$0/pharmacy/visit', // 药店拜访
    reviewVisitFeedback: '/api/criterion/review/$0/feedback', // 竞品信息反馈
    reviewVisitReport: '/api/criterion/review/$0/report', // 咨询报告
    reviewVisitOnlineVisit: '/api/criterion/review/$0/online/visit', // 远程拜访
    reviewVisitTerminal: '/api/criterion/review/$0/terminal', // 终端调研
    reviewVisitQuestionnaire: '/api/criterion/review/$0/questionnaire/$1', // 问卷调研
    reviewVisitQuestionnairePatient: '/api/criterion/review/$0/questionnaire/patient/$1', // 问卷调研（患者）
    reviewVisitActivity: '/api/criterion/review/$0/activity', // 学术活动
    reviewVisitInternal: '/api/criterion/review/$0/internal', // 内部培训
    reviewVisitActivityChild: '/api/criterion/review/$0/activity/$1/items', // 学术活动子项
    reviewVisitInternalChild: '/api/criterion/review/$0/internal/$1/items', // 内部培训子项
    reviewTerminalMaintenance: '/api/criterion/review/$0/terminal/maintenance', // 终端维护
    reviewCommercialFlow: '/api/criterion/review/$0/collect/commercial/flow', // 收集商业流向数据
    reviewTerminalMedicine: '/api/criterion/review/$0/collect/terminal/medicine', // 收集终端用药信息
    reviewCommercialDelivery: '/api/criterion/review/$0/commercial/delivery/agency/research', // 商业配送机构调研
    reviewHospitalAccess: '/api/criterion/review/$0/hospital/admittance', // 医院准入
    reviewPharmacyAccess: '/api/criterion/review/$0/pharmacy/admittance', // 药店准入
    reviewTypes: '/api/criterion/review/$0/types', // 数据汇总
    getFrequcySelectItems: '/api/$companyId/task/$promoteTaskId/activity/check/hold/options', // 会议频率性 获取可筛选项目

    // 操作指引
    companyWorkflow: '/api/company/$0/workflow', // 用户查看工作指引
    addCompanyWorkflow: '/api/company/$0/workflow', // 创建工作指引
    deleteWorkflow: '/api/company/$0/workflow/$1', // 删除指引
    updateWorkflowBack: '/api/company/$0/workflow/$1/back',
    updateWorkflowBudget: '/api/company/$0/workflow/$1/budget',
    updateWorkflowClient: '/api/company/$0/workflow/$1/client',
    updateWorkflowConfirmation: '/api/company/$0/workflow/$1/confirmation',
    updateWorkflowItem: '/api/company/$0/workflow/$1/item',
    updateWorkflowItemPrice: '/api/company/$0/workflow/$1/item/price',
    updateWorkflowItemScale: '/api/company/$0/workflow/$1/item/scale',
    updateWorkflowProtocol: '/api/company/$0/workflow/$1/protocol',
    updateWorkflowSettlement: '/api/company/$0/workflow/$1/settlement',
    updateWorkflowSubmission: '/api/company/$0/workflow/$1/submission',
    updateWorkflowUser: '/api/company/$0/workflow/$1/user',
    companyUserNum: '/api/company/$0/user/num', // 获取公司人数
    companyServiceItems: '/api/company/$0/workflow/items', // 公司服务项目列表
    workflowValidation: '/api/company/$0/workflow/$1/validation', // 检查项目
    checkSyncTask: '/api/company/$0/workflow/task', // 查询可同步的数据
    workflowHint: 'api/$0/workflow/hint',
    toggleWorkflowHint: '/api/workflow/hint/$0', // 同步按钮开/关
    workflowProtocolNum: '/api/company/$0/workflow/protocol/num', // 签约协议数量
    searchAllInformation: '/api/abnormal/$0/check/all/$1', // 一键检索
    // 推广任务指派用户
    associateList: '/api/customers/$0/associate/$1', // 客情信息列表

    taskAssignmentItems: '/api/$0/task/$1/assignment/items', // 推广任务获取可以指派的项目
    taskAssignmentUsers: '/api/$0/task/$1/assignment/users', // 推广任务获取可以指派的用户
    taskAssignmentPersonalItems: '/api/task/$0/assignment/items/$1', // 推广任务获取可以指派的项目 通过指派用户
    taskAssignmentList: '/api/task/$0/assignment', // 推广任务查看指派列表

    addTaskAssignment: '/api/task/$0/assignment', // 添加指派
    updateTaskAssignment: '/api/task/$0/assignment/$1', // 更新指派
    taskAssignmentMessage: '/api/task/assignment/message', // 指派的消息详情
    taskAssignmentMessageSender: '/api/task/assignment/message/sender', // 消息发送人列表
    taskAssignmentReceiver: '/api/task/assignment/message/receiver', // 查询指派给我的任务清单
    taskAssignmentReceiverMessage: '/api/task/$0/assignment/message/receiver', // 按任务查询我的消息列表
    taskAssignmentDetail: '/api/task/$0/assignment/detail', // 指派任务的详情
    taskAssignmentReadMessage: '/api/task/$0/assignment/message/unRead',
    taskAssignmentHasUnread: '/api/task/assignment/message/unRead', // 查看是否有未读消息
    taskAssignSummary: '/api/task/$0/assignment/summary', // 推广任务详情中查看汇
    checkCode: '/api/company/$0/code/check', // 检查企业ID
    checkoutBusinessinfo: '/api/qualification/standard/checkout/$0/businessinfo', // 检查企业信息是否完善

    // 微信绑定
    queryBind: '/api/wxggh/query/principal', // 查询是否绑定
    clearBind: '/api/wxggh/wx/login/out', // 解除微信公众号账号绑定
    getCodeWx: '/api/wxggh/get/dimensional/code/$0/2', // 获取二维码
    lisenBindResult: '/api/wxggh/get/scan/binding/error/$0:2', // 获取扫码绑定的错误信息

    // 签章相关接口
    signatureSure: '/api/fadada/company/$0/info/checkIsPass', // 判断是否认证
    getUrlFromFaDaDa: '/api/fadada/company/$0/verify/url', // 获取企业实名地址
    signatureInfo: '/api/fadada/company/$0/info', // 获取企业认证信息
    signatureList: '/api/fadada/company/$0/signature/list', // 获取企业签章列表
    signatureUserList: '/api/fadada/sign/users', // 签章授权列表
    addSignature: '/api/fadada/person/signature', // 添加个人签章
    addCompanySignature: '/api/fadada/company/$0/signature', // 添加公司签章
    delCompanySignature: '/api/fadada/company/$0/signature/$1', // 删除公司签章
    defaultSignature: '/api/fadada/company/$0/default/signature', // 设置默认签章
    fadadaWx: '/api/fadada/company/$0/signature/order/wx', // 微信购买
    fadadaWxStatus: '/api/fadada/signature/order/$0/status', // 微信购买状态
    fadadaPayList: '/api/fadada/company/$0/signature/order/page', // 支付明细列表
    fadadaPayAuth: '/api/fadada/company/$0/auth/$1', // 企业授权给个人
    fadadaCheck: '/api/fadada/check/infoAndAuth', // 签约检查
    fadadaSign: '/api/contact/company/$0/protocol/$1/fadada/sign', // 电子签约
    fadadaPage: '/api/fadada/company/$0/useInfo/page', // 签章使用记录
    fadadaVerifyAuth: '/api/fadada/company/$0/verify/auth/$1', // 公司授权个人实名
    fadadaisAbleESign: '/api/fadada/check/isAbleESign/$0', // 公司授权个人使用电子签章

    // 个人签章
    personSignInfo: '/api/fadada/person/info', // 个人签章信息
    personSignInfoList: '/api/fadada/person/signature/list', // 个人签章列表
    personVerify: '/api/fadada/person/verify', // 个人认证
    personGetCode: '/api/fadada/person/verify/code', // 获取验证码
    fadadaCheckAuth: '/api/fadada/company/$0/verify/auth/$1/check', // 查询是否在别的公司被授权

    getAddable: '/api/visit/companys/$0/addable', // 临床拜访获取可引用数量
    setSelfSignDefault: '/api/fadada/person/default/signature', // 设置个人默认签章
    deleteSelfSign: '/api/fadada/person/signature/$0', // 删除个人签章

    // 问卷
    questionnaireAssign: '/api/$0/questionnaire/$1/assignable', // 查询可以指派的客户
    questionnaireHasAssign: '/api/$0/questionnaire/$1/assignment', // 查询指派的客户
    deleteQusetAssign: '/api/questionnaire/assignment/$0', // 删除指派
    updateQuestAssignExpireType: '/api/questionnaire/$0/assignment/$1/hasExpireNum', // 修改自动开启开关
    questionnaireSurveyList: '/api/$0/questionnaire/survey', // 查询调研列表
    publicationUser: '/api/questionnaire/$0/publication/user', // 用户开始调研
    publicationCheck: '/api/questionnaire/$0/publication/check', // 结束调研的时候检查
    questionnairePlanUser: '/api/questionnaire/$0/plan/user', // 用户历程
    signatureIden: '/api/questionnaire/signature/identifier', // 获取签名token
    signatureImage: '/api/questionnaire/signature/image', // 签名
    planSign: '/api/questionnaire/$0/plan/sign', // 批量签到
    analyseList: '/api/questionnaire/$0/analyse', // 答卷分析列表
    analysable: '/api/questionnaire/$0/analysable', // 答卷分析历程
    analysePlanList: '/api/questionnaire/$0/analyse/$1/plan', // 关联的历程
    analysePlanListDel: '/api/questionnaire/$0/analyse/$1/$2', // 删除关联的历程
    analyseDetail: '/api/questionnaire/$0/analyse/$1', // 问卷分析
    analyseConclusion: '/api/questionnaire/analyse/$0/conclusion', // 问卷总结
    chooseAnalyse: 'api/questionnaire/$0/analyse/$1', // 选择历程
    questionnaireProducts: '/api/$0/questionnaire/product', // 问卷列表-产品
    quoteQuestionnaire: '/api/$0/questionnaire/quote', // 下游引用上游问卷
    activatedQuestionnaire: '/api/$0/questionnaire/activated', // 问卷启用/禁用
    queryQuestionnaireByProduct: '/api/$0/questionnaire/$1', // 根据产品查询问卷模版
    subcontractQuestionnaireTemplates: '/api/promote/task/subcontract/$0/template', // 活动分包问卷调研的模版
    updateQuestionnaireName: '/api/$0/questionnaire/name', // 修改问卷名称
    // 结算标准设置
    getStandardList: '/api/standard/settlements/$0/config/page', // 查询结算标准设置分页
    getStandardDetail: '/api/standard/settlements/config/$0/detail', // 查询结算标准设置详情
    getAddStandardDefault: '/api/standard/settlements/config/default', // 查询结算标准默认设置
    addStandSettlement: '/api/standard/settlements/$0/config', // 添加结算标准设置
    deleteStandSettlement: '/api/standard/settlements/config/$0', // 删除结算标准设置
    editStandSettlement: '/api/standard/settlements/$0/config/$1', // 修改结算标准设置
    editStandSettlementV2: '/api/standard/settlements/config/$1', // 修改结算标准设置
    getUseSettlement: '/api/standard/settlements/task/$0', // 查询推广任务启用的的结算标准
    queryLastTimeSettlement: '/api/standard/settlements/lastCreateTaskConfigInfo/$0', // 查询上次创建推广任务的结算标准信息
    getUpProtocolTemplateStandard: '/api/protocolTemplate', // 获取上游协议模版的结算标准
    getUpProtocolTemplateStandardDetail: '/api/$0/$1/standard/settlements', // 获取上游协议模版的结算标准的详情

    // 无线分包
    getContractInfo: '/api/unlimited/contract/assignment/types', // 获取自营模式信息
    getUnlimitTasks: '/api/$0/unlimited/contract/$1/tasks', // 任务选择列表
    getProjectByTask: '/api/$0/unlimited/contract/assignment/items', // 获取任务的服务项目
    importAssignment: '/api/$0/unlimited/contract/$1/assignment/items', // 导入服务项目
    logRecord: '/api/$0/self/support/log/$1/tasks', // 添加查看上游任务列表的日志

    // 问卷发布-医彩联医
    publishPlatform: '/api/questionnaire/$0/publication/platform', // 发布到平台
    getCityGroup: '/api/city/group/cities', // 获取城市组中的城市
    getCityGroupId: '/api/city/group/default', // 获取城市组id
    getRelatedDepart: '/api/relevance/departments/list', // 通过科室查询对应的关联科室
    questionPayStatus: '/api/questionnaire/order/$0/status', // 查询订单状态
    publishDetail: '/api/questionnaire/plan/$0/config', // 查看发布详情
    publishRedbag: '/api/questionnaire/plan/$0/red', // 获取计划的红包信息
    getRedbagDetail: '/api/questionnaire/plan/$0/red/detail', // 获取计划的红包领取详情
    // 信息服务费
    checkIsPay: '/api/questionnaire/plan/order/validation', // 检查历程是否需要购买
    payByplan: '/api/questionnaire/$0/plan/order', // 按问卷历程购买
    payBySheetplan: '/api/questionnaire/sheet/$0/order', // 按单份问卷购买
    getServiceFee: '/api/it/service/fee', // 查询单份信息服务费
    getServiceList: '/api/$0/questionnaire/orders', // 查询订单
    getQuestionUsers: '/api/$0/questionnaire/order/users?type=1', // 订单用户
    checkValidation: '/api/questionnaire/$0/sheet/validation', // 校验答卷列表是否需要购买
    payBySheetOrder: '/api/questionnaire/$0/sheet/order', // 答卷列表进行购买
    // 活动-收益-体现
    getAllIncome: '/api/income/activity', // 查询所有的收益活动
    getTotalREvenue: '/api/income/total/revenue/$0', // 查询总收益
    getWithDrawDeposit: '/api/withdraw/deposit', // 查询提现记录
    getWithdrawDetail: '/api/$0/withdraw/deposit', // 查看申请详情
    getBankFromNumber: '/api/card/number/$0/open/bank', // 根据银行卡号获取开户行信息
    getAllBanks: '/api/open/bank', // 获取所有的开户行
    applyWithdrawal: '/api/$0/withdraw/deposit', // 申请提现
    reapplySubmit: '/api/$0/withdraw/deposit', // 重新申请

    checkAllToggle: '/api/abnormal/$0/check/all/toggle/$1', // 检查一键检索是否需要提示

    // 资讯订阅
    getSubscribeList: '/api/wx/fwh/information/subscribe', // 查询订阅资讯列表
    getDimensionalCode: '/api/wx/fwh/$0/dimensional', // 订阅资讯的二维码
    cancelSubscibe: '/api/wx/fwh/$0/subscibe', // 取消订阅
    // 远程拜访
    longVisitList: '/api/$0/online/visit', // 远程拜访列表
    longRangeVisit: '/api/online/visit/$0', // 远程拜访详情
    deleteLongRangeVisit: '/api/online/visit', // 远程拜访列表批量删除

    addLongRangeTask: '/api/task/item/$0/market/online', // 添加远程拜访数据
    longRangeDetailList: '/api/task/item/market/$0/online/visits', // 远程拜访详情列表
    deleteRangeVisitDetail: '/api/task/item/market/$0/online/visits', // 拜访详情列表批量删除
    getLongCreateUsers: '/api/$0/online/visit/createUsers', // 远程拜访搜索条件 - 提交人
    getLongInfoTitle: '/api/$0/online/visit/information/title', // 远程拜访搜索条件 - 资讯名称
    longGetCanUse: '/api/$0/online/visit/addable', // 查询可添加的数据
    customerDetail: '/api/customers/detail/$0', // 客户详情
    infoResource: '/api/$0/online/visit/company', // 资讯来源

    // 临床拜访登记
    clinicalDaily: '/api/visit/$0/daily', // web端，查询一段日期的拜访数据
    clinicalLocation: '/api/visit/company/location?companyId=$0', // 查看定位设置
    getVisitAssociatedMessage: '/api/task/item/market/$0/associated/message', // 获取临床拜访被关联的提示信息
    clinicalUserList: 'api/customers/contacts/$0/user', // 选择拜访客户列表
    usersProducts: '/api/promote/user/products', // 查询用户所有推广产品
    factoryUser: '/api/$0/product/factory/user', // 搜索厂家
    promoteProductDetail: '/api/promote/products/detail', // 获取产品详细数据
    clinicalSubmit: '/api/visit/add', // 添加拜访
    checkTimeConflict: '/api/time/conflict/check', // 校验时间冲突
    checkTimeBatchConflict: '/api/time/conflict/visit/syncs/check', // 临床拜访批量同步时间冲突校验
    checkNum: '/api/visit/plan/num', // 查询本人未同步的计划数量
    visitSyncs: '/api/visit/syncs', // 同步列表
    getProductTemplate: 'api/promote/product/$0/template/$1', // 获取关键信息、反馈信息
    addFromPlan: '/api/visit/syncs', // 从计划中添加同步
    // 准入接口
    admittanceCreate: '/api/admittance/$0/template/createUser', // 模版创建人
    admittanceTemplate: '/api/admittance/$0/template', // 模版列表
    admittanceType: '/api/admittance/promoteType', // 模版类型
    templateChunk: '/api/admittance/template/$0/common/chunk/$1/$2', // 获取初始
    templateFiled: '/api/admittance/template/$0/common/field', // 常用字段
    addAdmittance: '/api/admittance/template', // 添加模版
    admittanceDetail: '/api/admittance/template/$0', // 模版详情
    admittanceExist: '/api/admittance/template/code/exist', // 查看模版编码
    admittanceCopy: '/api/admittance/template/copy', // 模版复制
    admittanceNumber: '/api/admittance/$0/register/promoteType', // 登记数量统计
    admittanceRegister: '/api/admittance/$0/register', // 登记列表
    admittanceCreateUser: '/api/admittance/$0/register/createUser', // 登记人列表
    admittanceDraft: '/api/admittance/$0/register/draft', // 登记草稿
    admittanceAllTemplate: '/api/admittance/$0/template/$1', // 查看所有模版
    admittanceEditing: '/api/admittance/$0/register/$1/editing', // 查询是否有退出的登记
    getCollectFlow: '/api/info/collect/register/company/$0', // 流向数据查询
    getCollectFlowV2: '/api/info/collect/register', // 流向数据查询
    createRegister: '/api/admittance/register', // 生成登记
    registerCheck: '/api/admittance/register/$0', // 查看登记
    updateField: '/api/admittance/register/$0/field', // 上传字段
    updateFieldV2: '/api/admittance/register/$0/field/v2', // 更新字段
    collectFlowGroup: '/api/info/collect/register/$0/flow/group', // 分组
    addFlowGroup: '/api/admittance/$0/info/collect/register/$1/flow/group', // 添加分组
    flowExport: '/api/admittance/$0/info/collect/register/flow/excel', // 流向数据Excel导入
    checkCollectFlowData: '/api/admittance/$0/info/collect/register/$1', // 查看已经引用流向数据
    checkVisitData: '/api/admittance/$0/visit', // 查看已经引用临床拜访数据
    addInfoRegister: '/api/admittance/$0/info/collect/register', // 添加终端调研数据
    addAdmittanceReg: '/api/admittance/register/$0/submit/v2', // 生成登记
    pharmacyVisitCompany: '/api/pharmacy/visit/company/$0', // 药店拜访
    pharmacyVisitEditDetail: '/api/visit/pharmacy/products/$0/visitDetail', // 药店拜访编辑页详情
    admittancePharmacy: '/api/admittance/$0/pharmacy/visit', // 已生成药店信息
    admittancePDF: '/api/admittance/register/$0/pdf', // 生成PDF
    admittanceDraftSave: '/api/admittance/register/$0/draft/v2', // 保存草稿箱
    admittanceFlowInfo: '/api/admittance/$0/info/collect/register/flow', // 查看流向分组详情
    attachmentsTask: '/api/task/item/$0/attachments', // 推广任务准入分页
    admittanceCompany: '/api/admittance/register/company/$0', // 线上添加分页
    attachmentImport: '/api/task/item/$0/attachment/import', // 线上添加
    visitPharmacyUsers: '/api/pharmacy/visit/company/$0/createUsers', // 药店创建人列表
    registerCompanyCreate: '/api/info/collect/register/company/$0/createUser', // 创建人列表
    admittanceSummaryDown: '/api/task/download/$0/admittance/summary', // 汇总表下载
    pharmacyExtra: '/api/admittance/$0/pharmacy/extra/districtIds', // 查询药店地区范围符合
    unSyncCustomersCount: '/api/$0/customers/unSync/count', // 待同步临床客户个数
    admittancecreateUser: '/api/admittance/register/company/$0/createUser', // 待同步临床客户个数
    registrantUsers: '/api/task/item/info/hint', // 登记人用户列表
    getRegisterUpdateTime: '/api/admittance/register/$0/updateTime', // 查询登记更新时间
    admittanceProducts: '/api/admittance/register/$0/products', // 准入产品列表

    shareLocalTask: '/api/task/$0/share', // 推广任务进行分享
    shareTaskList: '/api/task/$0/share', // 推广任务分享列表5
    taskIsShareimg: '/api/task/$0/sharing', // 推广任务分享是否都还有有效

    applyFeature: '/api/company/$0/feature/apply', // 申请升级超级会员
    serviceDetails: '/api/task/$0/assignment/item/$1', // 查看推广任务进程子项

    personalCompanyOrder: '/api/persinal/$0/order/wx', // 个人版订单
    unpaidPersonalDetail: '/api/persinal/$0/paying', // 待支付订单详情
    personalCompanyBusiness: '/api/company/personal/business', // 个人版业务管理详情

    infoFeedbackSetting: '/api/info/feedback/check/$0/condition', // 竞品条件设置

    // 协议授权
    protocolUsers: '/api/company/$0/protocol/assignment/users', // 协议授权列表
    protocolAssignment: '/api/company/$0/protocol/assignment/$1', // 查询已经授权协议
    deleteProtocolAssignment: '/api/company/$0/protocol/assignment', // 批量移除授权
    getCompanyProtocol: '/api/company/$0/protocol', // 查询公司协议
    protocolDetailUsers: '/api/company/$0/protocol/$1/assignment/users', // 协议授权信息

    // 操作日志
    operationLog: '/api/company_user_logs', // 公司用户操作日志列表（分页）
    operationLogDetail: '/api/company_user_log/$0', // 公司用户操作日志详情

    getProductList: '/api/company/products', // 产品列表
    // 更多附件
    attachmentItem: '/api/promote/task/$0/item/attachment', // 更多附件列表
    terminalItem: '/api/promote/task/item/attachment/$0/terminal', // 终端列表
    attachmentUrls: '/api/promote/task/item/$0/attachment/url', // 查询所有的附件
    attachmentFiles: '/api/promote/task/item/attachment/$0/file', // 保存附件
    updateAttachment: '/api/promote/item/attachment/$0', // 更新附件内容
    budgetMoreItems: '/api/promote/task/$0/budget/27', // 查看详情结算表
    batchUpdateAttachmentFile: '/api/promote/task/item/attachment/$0/file/batch', // 批量更新附件
    // 快速任务基
    getImportPromoteTasks: '/api/company/$0/promote/task/batch/import/data/query', // 查询可导入的医彩管家数据
    batchImportPromoteTask: '/api/company/$0/promote/task/batch/import', // 导入医彩管家数据
    deleteAttachment: '/api/promote/task/item/attachment/$0', // 删除附件
    attachmentSelect: '/api/promote/task/$0/item/attachment/select', // 获取所有项目

    // 信息收集-模版管理
    quoteTemplate: '/api/info/collect/$0/template/quote', // 引用委托方模版
    setTemplateIsDisable: '/api/info/collect/$0/template/$1/isDisable', // 设置模版是否禁用
    deleteTemplate: '/api/info/collect/$0/template/$1', // 删除模版和删除模版引用
    // 准入模版
    quoteAdmitTemplate: '/api/admittance/$0/template/quote', // 引用委托方模版
    setAdmitIsDisabled: '/api/admittance/$0/template/$1/isDisable', // 设置模版是否禁用
    deleteAdmitTemplate: '/api/admittance/$0/template/$1', // 删除模版
    // 任务巡航
    taskNavigate: '/api/promote/task/navigate/$0', // 获取巡航状态
    seTtaskNavigate: '/api/promote/task/navigate', // 设置巡航状态
    getSimilarTaskList: '/api/promote/task/$0/navigate/similar/$1', // 获取与巡航任务相似的任务或其他任务
    // 自动封装
    getAutoPack: '/api/promote/task/auto/pack', // 获取是否已开启自动封装
    setAutoPack: '/api/promote/task/auto/pack/$0', // 修改自动封装状态

    // 子预算
    checkBudgetItem: '/api/contact/protocol/$0/budget/item/all', // 根据协议id查询所有协议子预算
    verifyBudget: '/api/promote/task/verify/budget', // 校验预算和协议结余、协议子预算结余(创建推广任务前)
    getBudgetItemLog: '/api/contact/protocol/budget/item/$0/log/page', // 查询协议子预算日志
    changeBudgetItem: '/api/contact/protocol/budget/item/$0', // 协议预算中更新协议子预算
    getImporAddableTasks: '/api/company/$0/promote/task/batch/import/addable/query', // 查询可导入的医彩管家任务

    // 补充协议
    getSupplementModification: '/api/protocol/supplement/$0/modification', // 查看修改位置
    suppleSignedversion: '/api/contact/protocol/$0/allSignedVersion', // 单个协议所有已签约的版本

    // 日常汇报
    reportSignOptions: '/api/report/sign/options', // 查看签到设置项列表
    setReportSignOption: '/api/report/sign/option/type/$0', // 修改签到设置项
    workloads: '/api/report/workloads', // 员工工作量要求列表
    workloadOption: '/api/report/workload/option', // 获取公司工作量设置
    userWorkloadOption: '/api/user/report/workload/option/$0', // 获取人员工作量设置
    changeWorkloadOption: '/api/report/workload/$0/option', // 修改人员工作量设置
    setForceSign: '/api/force/sign', // admin设置强制签到开关
    legalHoliday: '/api/cms/legal/holiday/$0', // 获取某年的节假日
    addUserWorkloads: '/api/user/report/workload/option', // 批量添加人员工作量设置
    userWorkloads: '/api/user/report/workload/option', // 批量添加/删除人员工作量设置
    userChangeOpen: '/api/report/workload/$0', // 企业员工设置强制签到开关

    // 签到记录
    signedRecord: '/api/$0/signed/record/page', // 查询签到记录分页
    signedRecordCreateUser: '/api/$0/signed/record/createUser', // 查询签到记录创建人
    signedRecordDetail: '/api/signed/record/$0', // 获取签到记录详情
    deleteSignedRecord: '/api/signed/record', // 批量删除签到记录
    exportSignedRecord: '/api/signed/record/download/$0/$1', // 导出签到记录excel
    signedRecordSaveIds: '/api/signed/record/temp/save/ids', // 临时保存签到记录id集合
    getSignedRecordByIds: '/api/signed/record/photo', // 根据签到记录id集合获取图片列表

    // 智能报告
    openSmartReport: '/api/smart/report/open', // 开启智能报告
    smartReportList: '/api/smart/report/push', // 智能报告发布列表
    smartReportSet: '/api/smart/report/set', // 查询报告设置
    deleteReportSet: '/api/smart/report/set/$0', // 删除报告设置
    reportUser: '/api/smart/report/$0/user', // 获取智能报告中相关的签到用户
    getSmartReportById: '/api/smart/report/$0', // 获取统计详情
    intelligentReportPdf: '/node/intelligent/report/pdf', // 智能报告pdf下载
    reportTip: '/api/smart/report/tip', // 智能报告生成限制提示
    reportPushSmart: '/api/smart/report/push/immediately', // 在设置编辑保存之后 绑定微信 调用这个接口（推送）

    // 终端维护
    terminalList: '/api/info/collect/maintenance/web', // 终端维护列表-web
    terminalSubmission: '/api/info/collect/maintenance/web/submission', // 终端维护列表-web-提交人
    terminalProduct: '/api/info/collect/maintenance/web/product', // 终端维护列表-web-推广产品
    deleteTerminal: '/api/info/collect/maintenance', // 删除终端维护
    taskTerminalOffline: '/api/promote/task/terminal/maintenance/offline/$0', // 获取线下终端维护文件详情
    terminalDetail: '/api/info/collect/maintenance/register/$0', // 获取《编辑终端维详情》内容
    taskTerminalList: '/api/promote/task/$0/terminal/maintenance', // 已添加的终端维护列表
    batchUpdateTaskTerminalPrice: '/api/promote/task/$0/terminal/maintenance/batch', // 批量修改终端维护金额
    updateTaskTerminal: '/api/promote/task/terminal/maintenance/$0', // 修改终端维护金额
    batchDeleteTaskTerminal: '/api/promote/task/terminal/maintenance', // 从任务中移除终端维护
    batchDeleteTaskTerminalV2: '/api/promote/task/$0/terminal/maintenance', // 从指定任务中移除终端维护
    selectTaskMaintenanceList: '/api/promote/task/$0/terminal/maintenance/select', // 终端维护选择列表
    terminalMaintenanceSummary: '/api/task/download/$0/terminal/maintenance/summary', // 终端维护汇总表下载
    terminalDaily: '/api/info/collect/maintenance/daily', // 每日终端维护
    terminalTemplateList: '/api/info/collect/maintenance/templates', // 模版列表
    maintenanceHoapital: '/api/info/collect/maintenance/hospitals', // 终端管理医院列表
    maintenanceHoapital1: '/api/customer/hospital/company/user', // 终端管理医院列表-我的客户-医院
    maintenancePharmacy: '/api/info/collect/maintenance/pharmacies', // 终端管理药店列表
    getMaintenance: '/api/info/collect/maintenance/common', // 获取终端列表中对应字段的常用语
    addMaintenance: '/api/info/collect/maintenance', // 添加终端列表中对应字段的常用语
    deleteMaintenance: '/api/info/collect/maintenance/commonFields/$0', // 删除终端列表中对应字段的常用语
    addTerminal: '/api/info/collect/register', // 添加终端
    checkEdit: '/api/info/collect/maintenance/$0/update/status', // 校验是否能编辑
    maintenanceEditDetail: '/api/info/collect/maintenance/register/$0', // 终端维护详情
    maintenanceDetail: '/api/info/collect/maintenance/$0', // 终端维护列表详情
    maintenanceDelete: '/api/info/collect/maintenance/$0', // 删除终端维护
    terminalNameFetch: '/api/company/terminal', // 终端名称 获取其他终端

    // 药店定位分享
    // testLO: '/tencentMap/suggestion',
    testLO: '/api/map/suggestion', // 读取腾讯地图关键词输入提示
    getAreaIds: '/api/area/transform', // 根据省市区名称获取医彩系统中的区域对象
    createCompany: '/api/$0/pharmacys/createCompany', // 公司药店列表创建方(客户管理)
    pharmacyShare: '/api/pharmacy/share', // 生成分享码
    pharmacyShareLog: '/api/pharmacy/share/$0', // 公司分享码列表
    checkPharmCode: '/api/pharmacy/share/$0/check', // 校验分享码是否已使用
    pharmacyShareList: '/api/pharmacy/share/$0/page', // 分享码关联的的药店列表
    importPharmacy: '/api/pharmacy/share/$0/copy/$1', // 导入分享码的药店
    pharmacyCodeDetail: '/api/pharmacy/share/$0/detail', // 获取分享码详情

    // 组织架构
    getOrganization: '/api/$0/organization', // 获取整个组织架构的数据
    createDivision: '/api/$0/division', // 创建部门
    getDivisionDetail: '/api/$0/division/$1', // 获取部门详情
    getDivisionClerk: '/api/$0/division/$1/clerk', // 部门中的职员
    getAllUsers: '/api/$0/division/$1/users', // 部门查询公司用户列表
    changeChief: '/api/$0/division/$1/clerk/$2/chief', // 设置为主管
    deleteClerk: '/api/$0/division/$1/clerk/$2', // 删除员工
    changeUserDivision: '/api/$0/division/user/$1', // 用户批量调整部门
    userIsChief: '/api/division/user/chief', // 查询当前用户是否为主管

    // 讲者库
    speakerList: '/api/$0/speaker/page', // 分页查询-讲者库
    spearkerLevel: '/api/speaker/level', // 讲者等级查询
    addableList: '/api/speaker/$0/customer/contacts', // 查询可添加的客户列表
    addableApprover: '/api/speaker/approver', // 可选择审批人
    addSpeaker: '/api/speaker', // 添加讲者
    sperkerListMine: '/api/$0/speaker/page/mine', // 分页查询-我申请的讲者库
    sperkerDetail: '/api/speaker/$0/detail', // 讲者详情
    sperkerDetailLog: '/api/speaker/$0/log', // 审批日志
    speakerApproveList: '/api/$0/speaker/page/approve', // 分页查询-讲者审批
    speakerApprove: '/api/speaker/approve', // 审批讲者
    editSpeaker: '/api/speaker/$0', // 编辑讲者
    approveCount: '/api/$0/speaker/page/approve/toAudit/count', // 分页查询-讲者待审批数量
    sperkerDetailActivity: '/api/speaker/$0/activity', // 讲者详情-参会信息分页
    sperkerDetailActivityDetail: '/api/speaker/activity/$0/detail', // 讲者详情-参会信息分页-详情
    speakerProtocol: '/api/speaker/protocol/page', // 查询讲者协议分页
    speakerProtocolActivity: '/api/speaker/protocol/$0/activityPlan/choose', // 讲者协议-发起签约-选择活动
    sendSpeakerSign: '/api/speaker/protocol', // 添加讲者协议
    speakerProtocolDetail: '/api/speaker/protocol/$0/detail', // 查询讲者协议详情
    speakerProtocolLog: '/api/speaker/protocol/$0/log', // 查询讲者协议审批日志
    deleteSpeakerProtocol: '/api/speaker/protocol/$0/cancel', // 讲者协议作废
    uploadPaySpeaker: '/api/speaker/protocol/payment/upload', // 上传讲课协议支付凭证
    downloadPayment: '/api/speaker/protocol/$0/payment/download', // 下载讲课协议支付凭证
    queryPayment: '/api/speaker/protocol/$0/payment', // 查询讲课协议支付凭证
    xinfusheCheckIsAuth: '/api/speaker/protocol/$0/xinfushe/checkIsAuth', // 校验是否认证薪福社
    speakerProtocolTemplate: '/api/speaker/protocol/$0/template', // 讲课协议-发起签约-选择薪福社合同模版
    paySpeakerLabour: '/api/speaker/protocol/payment/labour', // 线上签约-讲课协议提交支付
    paySpeakerXfsBank: '/api/speaker/protocol/$0/xfsBank', // 查询薪福社收款信息
    paySpeakerXfsBankDownload: '/api/speaker/protocol/$0/xfsBank/download', // 薪福社收款信息下载
    speakerBankName: '/api/speaker/bankName', // 获取银行名称
    activityChildSpeaker: '/api/activity/$0/speaker/collect', // 活动登记-获取讲者参会信息和支付凭证集合
    supplierActiveProtocol: '/api/supplier/protocol/company/$0/active/list', // 公司已生效供应商服务
    // 活动现场
    chooseSpeaker: '/api/$0/speaker/page/activity', // 活动计划-选择讲者
    activityLocalType: '/api/activity/locale/$0/promote/type', // 查看活动现场归属推广项目统计页
    activityLocal: '/api/activity/locale', // 查看活动现场列表
    activityLocalDetail: '/api/activity/locale/detail', // 查看活动现场详情
    activityscenePhoto: '/api/activity/$0/locale/scenePhotos/extra', // 活动现场上传现场图片
    activityLocalSpeaker: '/api/activity/$0/speaker', // 活动计划-讲者信息

    // 推广任务回滚
    taskRollback: '/api/subcontract/rollback/task', // 分页查询分包任务回滚日志
    taskRollbackDetail: '/api/subcontract/rollback/$0/list', // 分页查询推广任务回滚日志
    rollbackCandidate: '/api/promote/task/subcontract/rollback/down', // 选择回滚任务列表
    candidateDetail: '/api/$0/task/rollback/candidate/detail', // 选择回滚任务列表-通过id查看任务详情
    taskRollbackSend: '/api/promote/task/subcontract/rollback', // 审批通过的任务状态回滚
    getTaskRollbackStatus: '/api/task/rollback/task/$0', // 获取任务回滚状态
    getStatisticByReceiver: '/api/company/$0/$1/statistic', // 根据服务商进行统计
    getStatisticByProtocol: '/api/company/protocol/$0/statistic', // 根据协议进行统计

    startCheckTer: '/api/$0/task/$1/terminal/maintenance/check/task', // 开始检索终端维护异常项
    getTerMaintain: '/api/$0/terminal/maintenance/criterion/$1', // 终端维护设置列表
    getTerTabs: '/api/$0/task/$1/terminal/maintenance/$2/check/result', // 详情
    getTerDetail: '/api/$2/task/$0/terminal/maintenance/month/$1/items', // 详情

    setFieldConfig: '/api/$0/$1/field/config', // GET  展示列表字段设置  PUT  更新展示列表字段设置

    // 我的应用
    mysupplierList: '/api/supplier/mine/app', // 我的应用列表
    mysupplierDetail: '/api/supplier/mine/app/$0', // 供应商服务信息详情
    mysupplierProtocol: '/api/supplier/mine/app/$0/protocol', // 协议列表
    mysupplierProtocolDetail: '/api/supplier/protocol/$0', // 协议详情
    mysupplierProtocolStop: '/api/supplier/mine/app/protocol/$0/stop', // 终止协议
    mysupplierPayment: '/api/speaker/protocol/payment/page', // 查询讲课协议支付记录分页
    mysupplierPaymentUser: '/api/speaker/protocol/payment/createUser', // 查询讲课协议支付记录创建人
    downloadMultiPayment: '/api/speaker/protocol/payment/batch/download', // 批量下载讲课协议支付凭证
    supplyToken: '/api/supplier/short/token', // 获取应用跳转token

    getPosts: '/api/posts', // 获取职务
    matchAuto: '/api/customers/match', // 自动匹配

    // 备案信息监测
    registerDetectionToggle: '/api/$0/task/$1/sales/register/detection/toggle', // 判断是否开启备案监测
    registerDetectionStatus: '/api/$0/task/$1/sales/register/detection/task', // 查询备案监测检索状态
    registerDetectionResult: '/api/$0/task/$1/sales/register/detection', // 查询监测结果
    registerDetectionResultPdf: '/api/$0/task/$1/sales/register/detection/pdf', // 查询监测结果

    // 推广总结报告
    getReport: '/api/summary/report/down/{taskId}', // 下游获取报告
    editRemark: '/api/summary/report/down', // 修改下游报告备注
    editReportItemRemark: '/api/summary/report/detail/down/remark', // 保存修改下游服务项目的备注 POST
    editReportItem: '/api/summary/report/detail/down/data', // 修改下游具体事项的数据与填写方式 PUT
    taskServiceItemAdd: '/api/summary/report/detail/down', // 下游的任务添加或删除推广服务项目时同步更新推广总结报告的事项
    exportReport: '/api/summary/report/down/export/{taskId}', // 导出报告
    getReportSystemDetail: '/api/summary/report/system/$0/$1', // 显示系统读取的值

    // 实名认证
    idCardOCR: '/api/person/id/crad/ocr', // 身份证ocr识别接口
    personVerifyCode: '/api/fadada/person/verify/code', // 获取个人实名认证验证码接口
    payForVerify: '/api/fadada/person/verify/order/wx', // 微信购买个人实名认证次数
    payResult: '/api/fadada/signature/order/$0/status', // 查询订单状态
    personVerifyInfo: '/api/fadada/person/threeEle', // 查询个人实名认证三要素
    realNamedInfo: '/api/user/real_named/info', // 快捷引用身份信息

    // 常用字段
    fetchCommonSentences: '/api/$companyId/info/collect/field/common', // 查询常用字段
    addCommonSentences: '/api/$companyId/info/collect/field/common', // 添加常用字段
    updateCommonSentences: '/api/$companyId/info/collect/field/common/$id', // 更新常用字段
    deleteCommonSentences: '/api/$companyId/info/collect/field/common/$id', // 删除常用字段
    // 报销付款
    reimburse: '/api/reimburse', // 报销付款 列表/新增
    reimburseDetail: '/api/reimburse/$0', // 报销付款详情
    reimburseApproval: '/api/reimburse/$0/approval', // 审批日志列表
    reimburseCustomField: '/api/reimburse/custom/field', // 自定义列表内容
    reimbursePdf: '/api/reimburse/download/pdf', // 下载pdf
    reimburseSignatureIdentifier: '/api/reimburse/signature/identifier', // 获取签字随机码
    reimburseStatus: '/api/reimburse/status', // 更新状态
    reimburseTemplate: '/api/reimburse/template', // 报销付款模版
    reimburseTemplateDetail: '/api/reimburse/template/$0', // 模板详情
    disableReimburseTemplate: '/api/reimburse/template/$0/disable', // 禁用模板
    usableReimburseTemplate: '/api/reimburse/template/$0/usable', // 启用模板
    copyReimburseTemplate: '/api/reimburse/template/copy/$0', // 复制模板
    reimburseTemplateByCode: '/api/reimburse/template/code/$0', // code查询模板
    reimburseTemplateCreators: '/api/reimburse/template/creator', // 创建人列表
    reimburseApprover: '/api/reimburse/approver', // 审批人列表-筛选条件
    reimburseProposer: '/api/reimburse/proposer', // 申请人列表-筛选条件
    reimburseCompanyApprover: '/api/reimburse/company/approver', // 审批人列表-创建时的审批人选择列表
    reimburseApprovalMine: '/api/reimburse/approval/mine', // 我的审批列表
    reimburseApprovalExist: '/api/reimburse/approval/exist', // 是否存在待审批
    reimburseSignature: '/api/reimburse/signature/identifier', // 获取签字随机码
    reimburseElectronicExist: ' /api/reimburse/electronic/exist', // 当前是否是电子审批
    reimburseDownloadPdf: '/api/reimburse/download/pdf', // 下载pdf
    reimburseAdminReject: '/api/reimburse/$0/admin/reject', // 超管驳回
    reimburseDownloadTemplateExcel: '/api/reimburse/template/$0/download/excel', // 下载excel模板
    reimburseUpload: '/api/reimburse/upload', // 上传excel
    reimburseTemplateFieldCommon: '/api/reimburse/template/field/common', // 常用字段
    // 个人电子签名
    fadadaPersonalWx: '/api/fadada/person/signature/order/wx', // 微信购买个人电子签章份数
    fadadaPersonalPayList: '/api/fadada/personal/verify/order/page', // 查询个人签章和实名认证购买支付明细分页
    fadadaPersonalPage: '/api/fadada/useInfo/page', // 查询个人版使用明细分页
    // 多页电子签章
    getBatchSignatureInfo: '/api/promote/task/$0/batch/signature/info', // 读取多页签章信息（状态、是否有签章/签名、是否开启签章）
    getBatchSignatureStep: '/api/promote/task/$0/batch/signature/step', // 读取多页签章状态
    receiverBatchSignatureToggle: '/api/promote/task/collection/batch/signature/toggle/$0', // 多页签章开关
    openTaskBatchSignature: '/api/promote/task/$0/collection/batch/signature', // 对任务开启多页电子签章
    batchSignatureCollectionGenerate: '/api/promote/task/$0/collection/batch/signature/generate/pdf', // 生成多页签章pdf
    batchSignatureCollectionGenerateStatus: '/api/promote/task/$0/collection/batch/signature/generate/pdf/status', // 生成多页签章pdf状态
    downloadPreviewBatchSignaturePdf: '/api/task/download/$0/collection/pdf', // 预览多页签章用到的pdf文件（下载不包含附件的pdf)
    getBatchSignatureFadadaUrl: '/api/promote/task/$0/collection/batch/signature/url', // 获取推广任务多页电子签章签署链接
    getBatchSignLog: '/api/promote/task/$0/collection/batch/signature/log', // 分页查询多页签章审批日志
    getBatchSignRefuseNote: '/api/promote/task/$0/batch/signature/refuse/note', // 读取上游对多页签章的驳回理由
    withdrawBatchSignature: '/api/promote/task/$0/collection/batch/signature/withdraw', // 乙方撤回多页签章
    downloadBatchSignedPdf: '/api/promote/task/$0/collection/batch/signature/generate/pdf/download', // 下载最后生成的多页签章pdf文件
    downloadBatchSignatureZip: '/api/promote/task/$0/collection/batch/signature/generate/pdf/zip/download', // 下载最后生成的多页签章zip文件

    // 临床拜访生成记录
    clinicShareLog: '/api/customer/share/$0',
    clinicShare: '/api/customer/share',
    clinicCodeCheck: '/api/customer/share/$0/check',
    clinicCodeDetail: '/api/customer/share/$0/detail',
    clinicCodeDetailList: '/api/customer/share/$0/page',
    clinicCodeImport: '/api/customer/share/$0/copy/$1 ',

    // 结算标准细分
    promoteTaskAllBudget: '/api/promote/task/$0/budget/group', // 任务结算表
    // 预算细分
    promotionTaskSubdivisionSupport: '/api/promote/task/$taskId/budget/subdivision/support', // 预算表细分支持
    // 任务分配信息
    getProjectGroupSettingForTaskID: '/api/task/assign/group/task/$0/$1', // 按任务查询项目组设置
    getTaskCompleteSetting: '/api/task/assign/draft/version/setting/$0', // 查询当前任务分配方式
    getProjectGroupSetting: '/api/task/assign/group/$0/$1', // 查询项目组设置
    getTaskBudgetProjectGroupInfo: '/api/task/assign/group/task/$0', // 按任务查询本任务的预算项目组详情
    // 批量导入临床拜访
    visitTemplateDownload: '/api/visit/template/download/v2', // 批量导入临床拜访信息模板下载
    asyncBatchImportVisit: '/api/visit/batch/import/async', // 批量导入临床拜访信息异步
    asyncBatchImportVisitResult: '/api/visit/batch/import/async/info', // 获取批量导入临床拜访信息异步的信息
    pharmacyVisitLocation: '/api/company/$0/pharmacy/visit/location',
    getPharmacyVisitAssociatedMessage: '/api/pharmacy/visit/$0/associated/message', // 获取药店拜访被关联的提示信息

    // 6091
    getComplianceList: '/api/compliance/info/$0',
    getAccessoryList: '/api/compliance/draft/$0/$1',
    getActivity: '/api/compliance/item/activity', // 获取所有活动类型
    accessoryDetail: '/api/compliance/item/$0/condition/$1', // 设置详情
    addConditonList: '/api/activity/registers/subkey', // 添加条件项
    upPreView: '/api/compliance/info/preview/$0', // 预览准则

    // 实名认证fadada
    getFadadaUrl: '/api/fadada/person/verify/url', // 获取法大大实名认证地址
    // 学术活动新增结算标准
    reasonableAmountList: '/api/$0/task/$1/activity/check/settlement/price',

    // 添加子项快捷导入
    getUpcom: '/api/down/contact/company', // 获取上游公司列表
    getChildItem: '/api/filled/$0/Activity/items/$1', // 获取子项

    // 6674新增筛选条件
    getProduct: 'api/report/file/product/$0', // 获取产品
    // 终端维护新增照片重复性异常详情
    terPhotoRepeatDetail: '/api/$0/task/$1/terminal/maintenance/image/repeat/result',
    // 学术活动举办重复性列表
    academicActivityRepeat: 'api/$0/task/$1/activity/check/repeat',
    academicActivityRepeatDetail: 'api/$0/task/activity/check/repeat/detail/$1',

    anomalousList: '/api/$0/anomalous', // 异常项设置列表
    anomalousCodeList: '/api/$0/anomalous/$1/items/toggle/$2', // 异常项设置开关列表

    // 渠道excel
    chanelDownload: '/api/channel/visit/template/download/v2', // 模板下载
    chanelUpload: '/api/channel/visit/batch/import/async', // 模板下载
    chanelUploadState: '/api/channel/visit/batch/import/async/info', // 模板下载
    // 药店excel
    pharmacyBatchDownload: '/api/pharmacy/visit/template/download/v2', // 模板下载
    pharmacyBatchUpload: '/api/pharmacy/visit/batch/import/async', // 模板下载
    pharmacyBatchUploadState: '/api/pharmacy/visit/batch/import/async/info', // 模板下载
    importOtherFile: '/api/users/private/openInfo',
    // 终端excel
    terminalTemplateDownload: '/api/info/collect/template/$0/download/v2', // 终端模版下载
    terminalBatchUpload: '/api/info/collect/template/$0/download/async', // 批量导入临床拜访信息异步
    terminalBatchUploadState: '/api/info/collect/template/$0/download/async', // 获取批量导入终端维护异步的信息

    // 修改任务名
    reWriteName: '/api/promote/tasks/$0/rename',
    replaceImage: '/api/image/replace', // 图片替换
    VisitDataRepeat: '/api/task/item/$0/visit/timeIntervalRationality/$1', // 临床拜访 拜访时间间隔合理性 检索结果

    getHospitalLocation: '/api/hospital/$0/location', // 读取指定的某个医院经纬度
    getPharmacyLocation: '/api/pharmacy/$0/location', // 读取指定的某个药店经纬度
    getChannelLocation: '/api/channel/$0/location', // 读取指定的某个渠道对应的城市区县大概的经纬度
    getTerminalLocation: '/api/terminal/$0/location', // 读取指定的某个其他终端的经纬度
    getPreLocation: '/api/common/terminal/$0/month/location', // 读取本月保存过的某个终端（医院/药店/渠道/其他终端等）的经纬度
    getTencentMap:
        'https://map.qq.com/api/gljs?v=1.exp&key=MGVBZ-EWV3O-SXEWO-SWOS6-JER3S-R6BSI&libraries=visualization', // 读取腾讯地图
    getTencentMapSuggestion: '/api/map/suggestion', // 读取腾讯地图关键词输入提示
    getTencentMapPointer: '/api/map/pointer', // 读取腾讯地图某个坐标的信息
    TerMaintenancePersonnel: 'api/task/terminal/maintenance/check/$0/abnormal/option', // 终端维护异常项-维护人员列表
    getTerAbnormalDetailData: 'api/task/terminal/maintenance/check/abnormal/detail', // 查询终端维护异常数据结果
    delTerAbnormalDetailData: 'api/$0/task/$1/terminal/maintenance/$2/check/result', // 删除终端维护异常数据结果
    getTaskAmount: 'api/promote/tasks/settlement/count', // 获取推广任务合计

    usingList: '/api/supplier/mine/app/using', // 我的应用-使用中的列表
    expiredList: 'api/supplier/mine/app/expired' // 我的应用-停用的列表
};

module.exports = api;
