import request from '@common/http';
import { ConfigProvider, LocaleProvider, message, Modal, Tooltip } from 'antd';
import zh_CN from 'antd/es/locale-provider/zh_CN';
import CryptoJS from 'crypto-js';
import { Prompt } from 'hera-ui';
import { isNil } from 'hera-utils';
import React from 'react';
import ReactDOM from 'react-dom';
import * as http from '../axios/index';
import event from './eventHub';
import { isImageHeic } from './file';
const key = CryptoJS.enc.Utf8.parse('gb4t976dwe2kj239');
const iv = CryptoJS.enc.Utf8.parse('8269571069351229');
// 文件下载
export { default as uploadFile } from '@common/uploadFile';

// 内容为空处理
export function isNull(content, defaultValue = '-') {
    if (typeof content === 'string' && content === '') {
        return defaultValue;
    }
    return content ?? defaultValue;
}

// const imgTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp']
export const imgTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/heic'];

/** 用户改图片格式后上传之后的图片格式 */
export const imageFormatValidationRegex = /x-oss-process=image\/format,(jpg|png|jpeg)$/i;

/**
 * 根据文件后缀名（包含.）判断是否是图片（支持所有格式，该方法一般用于头像等非业务用途图片上传）
 * @param type
 * @returns {boolean}
 */
export function determineAllImgWithSuffix(type) {
    return type && /(gif|jpg|jpeg|png|heic|tiff|bmp|webp)$/.test(type.toLowerCase());
}

/**
 * 根据文件后缀名（包含.）判断是否是图片（仅支持常见的几种静态图片格式，该方法一般用于业务逻辑中）
 * @param type
 * @returns {boolean}
 */
export function determineStaticImgWithSuffix(type) {
    // return type && /(jpg|jpeg|png|webp)$/.test(type.toLowerCase())
    return type && /(jpg|jpeg|png|heic)$/.test(type.toLowerCase());
}

/**
 * 判断是否是静态图片或PDF
 * @param type
 * @returns {boolean}
 */
export function determineStaticImgOrPdf(type) {
    // return type && /(jpg|jpeg|png|webp|pdf)$/.test(type.toLowerCase())
    return type && /(jpg|jpeg|png|pdf|heic)$/.test(type.toLowerCase());
}

/**
 * 判断文件大小是否不超过 size M
 * @param size
 */
export function inSizeM(size, max) {
    return size / 1024 / 1024 < max;
}

/**
 * 判断图片格式、文件名是否有特殊字符，图片大小限制
 * @param file
 */
export function isValidImg(file, max = 20) {
    if (file.type) {
        if (imgTypes.indexOf(file.type) === -1) {
            // message.error(`文件格式仅支持JPG、JPEG、PNG、WEBP`)
            message.error(`文件格式仅支持JPG、JPEG、PNG`);
            return false;
        }
    } else {
        if (!isImageHeic(file)) {
            message.error(`文件格式仅支持JPG、JPEG、PNG`);
            return false;
        }
    }

    const fileName = file.name;
    if (fileName.indexOf('#') > -1 || fileName.indexOf('%') > -1) {
        message.error('文件名请勿携带特殊符号');
        return false;
    }

    if (!inSizeM(file.size, max)) {
        message.error(`图片大小限制${max}M`);
        return false;
    }

    return true;
}

/**
 * 文件上传前校验
 * @param file
 * @returns {Promise<unknown>}
 */
export function beforeUpload(file) {
    return new Promise((resolve, reject) => {
        if (!isValidImg(file)) {
            return reject(false);
        }
        return resolve(true);
    });
}

/**
 *
 * @param info
 * @param max 最多数量限制
 * @returns {boolean}
 */
export function checkImg(info, maxCount = 3) {
    if (!isValidImg(info.file)) {
        return false;
    }
    if (info.fileList.length > maxCount) {
        message.destroy();
        message.error(`最多上传${maxCount}张图片`);
        return false;
    }
    return true;
}

/** 获取URL的文件名, 带后缀 */
export function getUrlFileName(url) {
    try {
        const parsedUrl = new URL(url);
        const { pathname } = parsedUrl;
        const segments = pathname.split('/').filter(Boolean);
        const filename = segments.pop();
        return filename;
    } catch (error) {
        console.error('Error parsing URL:', error);
        return '';
    }
}

/** 获取URL的文件名, 不带后缀 */
export function getUrlFileNameNoSuffix(url) {
    try {
        const filename = getUrlFileName(url);
        const extension = filename.split('.').shift();
        return extension;
    } catch (error) {
        console.error('Error parsing URL:', error);
        return '';
    }
}

/** 获取URL的文件后缀名 */
export function getUrlFileSuffix(url) {
    try {
        const filename = getUrlFileName(url);
        const extension = filename.split('.').pop();
        return extension;
    } catch (error) {
        console.error('Error parsing URL:', error);
        return '';
    }
}

/** 根据 https 分割多个图片地址 */
export function splitImgUrl(url) {
    if (!url) {
        return [];
    }
    // 前缀
    const prefix = /,?https?:\/\//g;
    return url
        .split(prefix)
        .filter((item) => item)
        .map((item) => `https://${item}`);
}

/**
 * 获取时长(单位秒)
 */
export function formatSeconds(value) {
    let secondTime = parseInt(value); // 秒
    let minuteTime = 0; // 分
    let hourTime = 0; // 小时
    if (secondTime > 60) {
        // 如果秒数大于60，将秒数转换成整数
        // 获取分钟，除以60取整数，得到整数分钟
        minuteTime = parseInt(secondTime / 60);
        // 获取秒数，秒数取佘，得到整数秒数
        secondTime = parseInt(secondTime % 60);
        // 如果分钟大于60，将分钟转换成小时
        if (minuteTime > 60) {
            // 获取小时，获取分钟除以60，得到整数小时
            hourTime = parseInt(minuteTime / 60);
            // 获取小时后取佘的分，获取分钟除以60取佘的分
            minuteTime = parseInt(minuteTime % 60);
        }
    }
    let result = `${parseInt(secondTime)}秒`;

    if (minuteTime > 0) {
        result = `${parseInt(minuteTime)}分${result}`;
    }
    if (hourTime > 0) {
        result = `${parseInt(hourTime)}小时${result}`;
    }
    return result;
}

/**
 * 将数字金额转化为中文
 */
export function convertCurrency(money) {
    if (!money) {
        return '零元整';
    }
    // 汉字的数字
    const cnNums = ['零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖'];
    // 基本单位
    const cnIntRadice = ['', '拾', '佰', '仟'];
    // 对应整数部分扩展单位
    const cnIntUnits = ['', '万', '亿', '兆'];
    // 对应小数部分单位
    const cnDecUnits = ['角', '分', '毫', '厘'];
    // 整数金额时后面跟的字符
    const cnInteger = '整';
    // 整型完以后的单位
    const cnIntLast = '元';
    // 最大处理的数字
    const maxNum = 999999999999999.9999;
    // 金额整数部分
    let integerNum;
    // 金额小数部分
    let decimalNum;
    // 输出的中文金额字符串
    let chineseStr = '';
    // 分离金额后用的数组，预定义
    let parts;
    let newMoney = parseFloat(money);
    if (newMoney >= maxNum) {
        // 超出最大处理数字
        return '';
    }
    if (newMoney === 0) {
        chineseStr = cnNums[0] + cnIntLast + cnInteger;
        return chineseStr;
    }
    // 转换为字符串
    newMoney = newMoney.toString();
    if (newMoney.indexOf('.') === -1) {
        integerNum = newMoney;
        decimalNum = '';
    } else {
        parts = newMoney.split('.');
        integerNum = parts[0];
        decimalNum = parts[1].substr(0, 4);
    }
    // 获取整型部分转换
    if (parseInt(integerNum) > 0) {
        let zeroCount = 0;
        const IntLen = integerNum.length;
        for (let i = 0; i < IntLen; i++) {
            const n = integerNum.substr(i, 1);
            const p = IntLen - i - 1;
            const q = p / 4;
            const m = p % 4;
            if (n === '0') {
                zeroCount += 1;
            } else {
                if (zeroCount > 0) {
                    chineseStr += cnNums[0];
                }
                // 归零
                zeroCount = 0;
                chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
            }
            if (m === 0 && zeroCount < 4) {
                chineseStr += cnIntUnits[q];
            }
        }
        chineseStr += cnIntLast;
    }
    // 小数部分
    if (decimalNum !== '') {
        const decLen = decimalNum.length;
        for (let i = 0; i < decLen; i++) {
            const n = decimalNum.substr(i, 1);
            if (n !== '0') {
                chineseStr += cnNums[Number(n)] + cnDecUnits[i];
            }
        }
    }
    if (chineseStr === '') {
        chineseStr += cnNums[0] + cnIntLast + cnInteger;
    } else if (decimalNum === '') {
        chineseStr += cnInteger;
    }
    return chineseStr;
}

// 未开通会员提示
export function showOpenMemberModal(history) {
    if (localStorage.getItem('ISDIRECTLY') === 'true') {
        Modal.info({
            title: '您的企业未开通会员或已到期，无法进行此操作'
        });
    } else if (localStorage.getItem('COMPANYTYPE') === '2') {
        Modal.confirm({
            title: '您的个人版未开通会员或会员已到期，无法进行此操作',
            okText: '立即开通',
            cancelText: '取消',
            iconType: 'exclamation-circle',
            onOk() {
                history.push('/setting/MemberCenter');
            }
        });
    } else {
        Modal.confirm({
            title: '您的企业未认证或已到期，无法进行此操作',
            okText: '查看详情',
            cancelText: '取消',
            iconType: 'exclamation-circle',
            onOk() {
                history.push('/setting/MemberCenter');
            }
        });
    }
}

/**
 * 根据产品对象获取产品全称
 */
export function getProductStr(i) {
    let stringProduct = '';
    if (i) {
        stringProduct = `${i.name}${i.commodityName ? ` (${i.commodityName}) ` : ''}【${i.specification}/${
            i.drugForm
        }】,${i.manufacturer ? i.manufacturer : i.manufacturerEn}`;
    }
    return stringProduct;
}
/**
 * 根据产品对象获取产品全称
 * 部分位置读取产品信息的字段不一致
 */
export function getProductStrV2(row) {
    let stringProduct = '';
    if (row) {
        stringProduct = `${row.promoteProductName || row.name}${row.commodityName ? `(${row.commodityName})` : ''}${
            row.specification || row.drugForm
                ? `【${row.specification || ''}${row.specification && row.drugForm && '/'}${row.drugForm || ''}】`
                : ''
        }，${row.manufacturer ? row.manufacturer : row.manufacturerEn}`;
    }
    return stringProduct;
}

/**
 * 获取地区对象获取地区的名称
 */
export function getAreaStr(i) {
    if (i.type === 1) {
        return '全国;';
    }
    if (i.type === 2) {
        return i.provinceId === 22 || i.provinceId === 1 || i.provinceId === 2 || i.provinceId === 9
            ? `${i.provinceName}/全市;`
            : `${i.provinceName}/全省;`;
    }
    if (i.type === 3) {
        return `${i.provinceName}/${i.cityName};`;
    }
    if (i.type === 5) {
        return `${i.provinceName}/${i.cityName}/${i.districtName};`;
    }
    if (i.type === 4) {
        return `${i.hospitalName};`;
    }
    return '';
}

// 跳转至应用中心
export function openApplyCenter() {
    http.supplyToken().then((res) => {
        if (process.env.NODE_ENVE === 'prod') {
            window.open(`https://apps.ecaisys.com/preparePage?token=${res.data}&type=2`);
        } else if (process.env.NODE_ENVE === 'pre') {
            window.open(`https://apps.pre.ecaisys.com/preparePage?token=${res.data}&type=2`);
        } else {
            window.open(`http://apps.ecaiabc.com/preparePage?token=${res.data}&type=2`);
        }
    });
}

/**
 * 跳转至管家企业信息页面
 */
export function goToGuanjiaCompanyInfo() {
    http.supplyToken().then((res) => {
        let url;
        const env = process.env.NODE_ENVE;
        if (env === 'prod') {
            url = 'https://gj.ecaisys.com';
        } else if (env === 'pre') {
            url = 'https://gj.pre.ecaisys.com';
        } else if (env === 'dev') {
            url = 'http://gj.ecaiabc.com';
        } else if (env === 'dev2') {
            url = 'https://gjdev.ecaiabc.com';
        } else {
            url = 'http://gj.ecaiabc.com';
        }
        url = `${url}/#/setting/companyInfo`;
        window.open(url);
    });
}

/**
 * 数组排序
 * @param list 数组
 * @param property 要排序的属性
 * @param asc true/false 从小到大（true)，或从大到小（false)，默认为true
 * @returns {*[]|*}
 */
export function arraySort(list, property, asc) {
    if (typeof asc === 'undefined' || asc) {
        asc = 1;
    } else {
        asc = -1;
    }
    if (!list || list.length == 0) {
        return [];
    }
    return list.sort((a, b) => {
        if (!a) {
            return 1 * asc;
        }
        if (!b) {
            return -1 * asc;
        }

        if (!property) {
            return (b - a) * asc;
        } else {
            if (!a[property]) {
                return 1 * asc;
            }
            if (!b[property]) {
                return -1 * asc;
            }
            return (a[property] - b[property]) * asc;
        }
    });
}

/**
 * 根据输入省市区ID读取省市区名称
 * @param ids
 * @param data
 * @returns {string}
 */
export function getAreaName(ids, data) {
    let string = '';
    const priId = ids[0];
    const cityId = ids[1];
    const districtId = ids[2] ? ids[2] : -1;
    _.map(data, (i) => {
        if (i.value == priId) {
            string = string + i.label;
            _.map(i.children, (j) => {
                if (j.value == cityId) {
                    string = string + j.label;
                    _.map(j.children, (k) => {
                        if (k.value == districtId) {
                            string = string + k.label;
                        }
                    });
                }
            });
        }
    });
    return string;
}

/**
 * 判断输入框内容是否为空（纯空格也判断为空）
 * @param text
 * @returns {boolean}
 */
export function textIsBlank(text) {
    return !text || text.trim().length == 0;
}

/**
 * 统计搜索条件选择个数
 * @param params
 * @returns {number}
 */
export function handleBadgeCount(...params) {
    return params.filter((param) => param === true || (param != '' && param != undefined)).length;
}

// 学术会议大中小 细分
export function smeetingSubdivision(items) {
    return (items || []).sort((a, b) => a.serviceItemId - b.serviceItemId);
}
// description描述拆分
export function descriptionSplit(description) {
    const pattern = /\d+/g;
    const matchs = description.match(pattern);
    const len = matchs ? matchs[0].length : 0;
    return description.substr(0, description.length - 1 - len);
}

export function setDefaultCompany(companyId) {
    return new Promise((resolve, reject) => {
        $.AJAX({
            url: `${API.userInCompany}/${companyId}`,
            type: 'PUT',
            success: () => resolve(),
            error: () => reject()
        });
    });
}

export function logout() {
    window.localStorage.removeItem('AUTHORIZATION'); // authorization
    window.localStorage.removeItem('COMPANYID'); // authorization
    window.localStorage.removeItem('COMPANYNAME'); // authorization
    window.localStorage.removeItem('AUTHENTICATIONSTATUS'); // authorization
    window.localStorage.removeItem('USERNAME'); // authorization
    window.localStorage.removeItem('SHOWCREATE'); // authorization
    window.localStorage.removeItem('USERID'); // authorization
    window.localStorage.removeItem('SHOWENTER'); // authorization
    window.localStorage.removeItem('SUPER'); // authorization
    window.localStorage.removeItem('INDEX'); // authorization
    window.localStorage.removeItem('SELECTTYPE'); // authorization
    window.localStorage.removeItem('FREEZE'); // authorization
    window.localStorage.removeItem('TASKITEMID'); // authorization
    window.localStorage.removeItem('ADDRESS'); // authorization
    window.localStorage.removeItem('TIME'); // authorization
    window.localStorage.removeItem('ISDIRECTLY'); // authorization
    window.localStorage.removeItem('COMPANYTYPE'); // authorization
    window.localStorage.removeItem('IsRealName'); // authorization
    window.localStorage.removeItem('isSuper'); // authorization
    window.localStorage.removeItem('isPass'); // authorization
    window.localStorage.removeItem('SYS_EDITION');
    try {
        Prompt.dredgeIntercept(() => {
            // 放一个标识，登录页面需要刷新
            window.sessionStorage.setItem('reloadLogin', 'true');
            window.location.href = '/#/login';
        });
    } catch (err) {
        // 放一个标识，登录页面需要刷新
        window.sessionStorage.setItem('reloadLogin', 'true');
        window.location.href = '/#/login';
    }
}

// 活动登记活动子项数量单位
export function getSubkeyNumUnit(type) {
    switch (type) {
        case 1:
            return '人';
        case 2:
            return '天';
        case 4:
            return '人·天';
        case 5:
            return '辆';
        case 6:
            return '位';
        case 7:
            return '场';
        case 8:
            return '人·餐';
        case 9:
            return '人·次';
        case 10:
            return '人·趟';
        case 11:
            return '间·天';
        default:
            return '';
    }
}

/**
 * 创建弹窗
 * @param {Modal}
 * @return {object} show
 */
export function Dialog(Com) {
    return (options = {}) => {
        let node;
        function close() {
            ReactDOM.unmountComponentAtNode(node);
        }
        return {
            show: () => {
                node = document.createElement('div');
                ReactDOM.render(
                    <LocaleProvider locale={zh_CN}>
                        <Com {...options} onClose={close} />
                    </LocaleProvider>,
                    node
                );
            }
        };
    };
}

/**
 * 文本复制
 * @param {text}
 * @return {*}
 */
export function copy(text) {
    let input = document.getElementById('copy-input');
    if (!input) {
        const { body } = document;
        input = document.createElement('input');
        body.appendChild(input);
        input.style.opacity = 0;
        input.id = 'copy-input';
    }
    input.value = text;
    input.select();
    input.setSelectionRange(0, input.value.length);
    document.execCommand('Copy');
    input.blur;
    input.remove();
}

// 轮询请求
export function polling(fn, timeout) {
    let timer;
    const cb = async () => {
        if (fn) {
            const res = await fn();
            if (res === false) return;
        }
        if (timer) {
            timer = setTimeout(cb, timeout);
        }
    };
    timer = setTimeout(cb, 0);
}

// 劳动合同 获取法大大 已经签章签字的pdf
export const openPdf = async (laborContractId, url) => {
    if (!laborContractId && !url) return;
    if (!laborContractId && url) {
        window.open(url);
        return;
    }
    try {
        message.loading('正在加载pdf文件,请稍后...');
        const res = await request({
            url: `/api/labor/contract/${laborContractId}/pdf`,
            responseType: 'blob'
        });
        const url = window.URL.createObjectURL(res.data);
        message.destroy();
        window.open(url);
    } catch (err) {
        message.destroy();
        message.error('加载pdf文件失败!');
    }
};
/**
 * 如果腾讯地图定位信息中缺少省市区,需要通过其经纬度再获取一次信息,来取得其省市区
 * @param {*} Latitude 纬度
 * @param {*} longitude 经度
 */
export const getLocationInfoByLongitude = (latitude, longitude) => {
    return new Promise((resolve, reject) => {
        const parameters = {
            longitude,
            latitude
        };
        $.AJAX({
            url: API.getTencentMapPointer,
            data: parameters,
            type: 'GET',
            dataType: 'JSON',
            success: (data) => {
                resolve(data);
            }
        });
    });
};

/**
 * 获取不同环境的网址
 * @param {*} env 环境变量
 * @returns
 */
export function getEnvLink(env = process.env.NODE_ENVE) {
    switch (env) {
        case 'dev':
            return 'https://ysdev.ecaiabc.com/#/';
        case 'dev2':
            return 'https://ys.dev.ecaiabc.com/#/';
        case 'test':
            return 'http://ys.ecaiabc.com/#/';
        case 'prod':
            return 'https://ys.ecaisys.com/#/';
        case 'pre':
            return 'https://ys.pre.ecaisys.com/#/';
        default:
            return;
    }
}

// 动态修改面包屑
// demo
// setBreadcrumb('市场管理,远程拜访')
// or setBreadcrumb(['市场管理','远程拜访'])
// or setBreadcrumb([{name:'市场管理',url:'xxx'},{name:'远程拜访',url:'xxx'}])
export function setBreadcrumb(data) {
    // 延迟到下一帧执行，将修改面包屑操作放在layout 组件修改面包屑之后
    setTimeout(() => {
        event.emit('setBreadcrumb', data);
    });
}
export function limitStr(str, byteLimit = 255) {
    // 匹配汉字正则
    const chinRegexpRule = /[\u2E80-\u9FFF]/g;
    // rule  chin*4 eng*1.2
    let matchs = str.match(chinRegexpRule);
    const totalLetterCount = str.length;
    if (!matchs) matchs = [];

    return matchs.length * 4.3 + (totalLetterCount - matchs.length) * 1.2 > byteLimit;
}

/**
 * @description 当前用户是否是申请人
 * @param {*} submitter
 * @return {*} boolean
 */
export const isSubmitter = (submitter) => {
    return submitter == localStorage.getItem('USERID');
};

/**
 * @description 当前用户是否是审批人
 * @param {*} approverId
 * @return {*} boolean
 */
export const isApprover = (approverId) => {
    return approverId == localStorage.getItem('USERID');
};

/**
 * AES加密
 * @param text
 * @returns {string}
 */
export function encryptWithAES(text) {
    const encryptedText = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(text), key, {
        iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    return CryptoJS.enc.Base64.stringify(encryptedText.ciphertext);
}

/**
 * @description: 推广产品列表 内容处理
 * @param {array} productVOList 推广产品数据
 * @param {number} len 取前几个 不填取全部
 * @param {number} strLen 每个产品预览的长度，默认6
 * @return {string} 推广产品 str 后内容
 */
export function productsDetailName(productVOList, options = {}, strLen = 6) {
    const { valueKey = 'detailName', joinValue = '；', len } = options;
    const resetProductVOList = Array.isArray(productVOList) ? productVOList : [];
    if (resetProductVOList.length === 0) {
        return null;
    }
    const data = resetProductVOList.map((item) => {
        let content = typeof valueKey === 'function' ? valueKey(item) : item[valueKey];
        // joinValue 采用 换行形式的，去掉 内容里面的换行符
        if (joinValue === '\n') {
            content = content.replace(/\n/g, '');
        }
        return content;
    });
    if (len) {
        const list = data.filter((item, index) => index + 1 <= len);
        return (
            <div>
                {list.map((item) => {
                    return (
                        <div key={item}>
                            <Tooltip title={item}>
                                {item.length > strLen ? `${item.slice(0, strLen)}...` : item}
                            </Tooltip>
                        </div>
                    );
                })}
                {data.length > len ? <div>...（共{data.length}个）</div> : null}
            </div>
        );
    }
    return data.join(joinValue);
}

/**
 * 校验变量是否为数字且值不为NaN
 * @param {*} num
 * @returns
 */
export function checkNumWhetherEmpty(num) {
    const isNumber = Object.prototype.toString.call(num) === '[object Number]';
    return isNumber ? (isNaN(num) ? false : true) : false;
}

/**
 * 展示终端位置信息
 * @param {*} visitAddress
 * @param {*} needOmitting 是否需要省略
 * @returns
 */
export function showVisitAddress(visitAddress, needOmitting = false) {
    if (!visitAddress?.address || !visitAddress?.name) {
        return '-';
    }
    const { address, name } = visitAddress;
    const str = address + name;
    return str.length > 5 && needOmitting ? <Tooltip title={str}>{`${str.slice(0, 5)}...`}</Tooltip> : str;
}

/** 判断当前环境是不是 医彩药事-企业版 */
export function isCompanyVersion() {
    const COMPANY_TYPE = localStorage.getItem('COMPANYTYPE');
    return COMPANY_TYPE === '1';
}

/** 判断当前环境是不是 医彩药事-个人版 */
export function isPersonalVersion() {
    const COMPANY_TYPE = localStorage.getItem('COMPANYTYPE');
    return COMPANY_TYPE === '2';
}

/** 判断当前环境是不是 直属医彩药事 */
export function isDirectly() {
    const ISDIRECTLY = localStorage.getItem('ISDIRECTLY');
    return ISDIRECTLY === 'true';
}

/** 操作直属版提示 */
export async function operateDirectly() {
    if (isDirectly()) {
        Modal.warning({
            title: '温馨提示',
            content: '当前企业为直属版药事企业。请联系企业管理员到【医彩管家】操作。'
        });
        return Promise.reject();
    }
}

/**
 * 处理表格列的最小宽度
 * @param {*} children
 * @param {*} titleLength 表头字数
 * @param {*} customWidth 自定义宽度
 * @returns
 */
export function handelColumnWidth(children, titleLength, customWidth = 0) {
    return <div style={{ minWidth: customWidth || titleLength * 15 }}>{children}</div>;
}

/** 结算金额最大值 */
export const maxSettlement = 999999999.99;

/** 获取有效字符 不计空格和换行符 */
export const getValidCharacters = (str) => {
    // 使用正则表达式替换所有空格和换行符
    return (str ?? '').replace(/[\s\n]/g, '').length;
};

// 推广任务-推广项目-添加数据-空胎 跳转到对应项目添加数据页面
const tableAddDataEmptyJump = (type) => {
    switch (type) {
        case 1:
            return window.open('/#/Market/VisitPage');
        case 28:
            return window.open('/#/Market/RemoteVisit');
        case 2:
            return window.open('/#/Market/Pharmacy');
        case 3:
            return window.open('/#/Market/Channel');
        case 4:
            return window.open('/#/Market/Terminal');
        case 5:
            return window.open('/#/Market/BusinessChannel');
        case 6:
        case 7:
        case 8:
        case 9:
            window.localStorage.PROMOTETYPE = 1;
            return window.open('/#/AcademicPlan/PlanPage/planList');
        case 10:
            window.localStorage.PROMOTETYPE = 2;
            return window.open('/#/AcademicPlan/PlanPage/academicList');
        case 22:
            return window.open('/#/ReportList/MyReport');
        case 20:
        case 21:
            return window.open('/#/ReportList/Questionnaire');
        case 23:
            window.localStorage.TYPENAME = '医院准入（三级）';
            return window.open('/#/MarkDevelop/MarkRegist/RegistrationDetail?id=1&closePage=true');
        case 24:
            window.localStorage.TYPENAME = '医院准入（二级）';
            return window.open('/#/MarkDevelop/MarkRegist/RegistrationDetail?id=2&closePage=true');
        case 25:
            window.localStorage.TYPENAME = '医院准入（二级以下）';
            return window.open('/#/MarkDevelop/MarkRegist/RegistrationDetail?id=3&closePage=true');
        case 26:
            window.localStorage.TYPENAME = '药店准入';
            return window.open('/#/MarkDevelop/MarkRegist/RegistrationDetail?id=4&closePage=true');
        case 17:
            window.localStorage.TYPENAME = '终端调研';
            window.localStorage.TYPE = 17;
            return window.open('/#/MessageGa/Registration/RegistrationDetail?id=7&closePage=true');
        case 16:
            window.localStorage.TYPENAME = '商业配送机构调研';
            window.localStorage.TYPE = 16;
            return window.open('/#/MessageGa/Registration/RegistrationDetail?id=6&closePage=true');
        case 15:
            window.localStorage.TYPENAME = '竞品信息反馈';
            window.localStorage.TYPE = 15;
            return window.open('/#/MessageGa/Registration/RegistrationDetail?id=5&closePage=true');
        case 11:
            window.localStorage.TYPENAME = '收集商业流向数据';
            window.localStorage.TYPE = 11;
            return window.open('/#/MessageGa/Registration/RegistrationDetail?id=1&closePage=true');
        case 12:
            window.localStorage.TYPENAME = '收集医院库存信息';
            window.localStorage.TYPE = 12;
            return window.open('/#/MessageGa/Registration/RegistrationDetail?id=2&closePage=true');
        case 13:
            window.localStorage.TYPENAME = '收集其他终端库存信息';
            window.localStorage.TYPE = 13;
            return window.open('/#/MessageGa/Registration/RegistrationDetail?id=3&closePage=true');
        case 14:
            window.localStorage.TYPENAME = '收集终端用药信息';
            window.localStorage.TYPE = 14;
            return window.open('/#/MessageGa/Registration/RegistrationDetail?id=4&closePage=true');
        case 18:
            window.localStorage.TYPENAME = '区域药品招标信息收集';
            window.localStorage.TYPE = 18;
            return window.open('/#/MessageGa/Registration/RegistrationDetail?id=8&closePage=true');
        case 19:
            window.localStorage.TYPENAME = '区域临床路径信息收集';
            window.localStorage.TYPE = 19;
            return window.open('/#/MessageGa/Registration/RegistrationDetail?id=9&closePage=true');
        case 29:
            return window.open('/#/AcademicPlan/PlanPage/lineLive');
        case 41:
            window.localStorage.TYPENAME = '收集药店库存信息';
            window.localStorage.TYPE = 41;
            return window.open('/#/MessageGa/Registration/RegistrationDetail?id=11&closePage=true');
        case 42:
            return window.open('/#/Market/patient');
        case 43:
            return window.open('/#/Market/science');
    }
};

/** 表格添加数据空态 */
export const tableAddDataEmpty = ({ type, callback }) => {
    return {
        emptyText: (
            <div style={{ margin: '32px 0' }}>
                <img
                    style={{ width: 62, height: 40, marginBottom: '8px' }}
                    src='https://img.hooshine.com/ys/emptyData@2x.png'
                />
                <p>
                    暂无可添加数据，
                    <a
                        onClick={(e) => {
                            e.stopPropagation();
                            callback?.();
                            if (type) {
                                tableAddDataEmptyJump(type);
                            }
                        }}
                    >
                        前往创建
                    </a>
                </p>
            </div>
        )
    };
};

/** 是否有权限跳转到推广任务详情 */
export function isRoleOpenTaskDetail(options) {
    const { serviceItemId, refType, dataCompanyId } = options;
    if (refType === 99) {
        return true;
    }
    // 3个拜访 如果 dataCompanyId 不是当前企业的不让跳转
    if ([1, 2, 3].includes(serviceItemId) && dataCompanyId != localStorage.getItem('COMPANYID')) {
        return false;
    }
    return true;
}

/** 打开推广任务详情 */
export function openTaskDetail(options) {
    const { serviceItemId, dataId, refType, taskId, dataCompanyId, fields } = options;
    if (refType === 99) {
        const name = fields.find((item) => item.name === '照片来源')?.value;
        if (name) {
            localStorage.setItem('ADJUNCTNAME', name);
        }
        window.open(
            `/#/Business/PromotionPlan/BusinessPerfectInfo/UploadFIleArea/${dataId}?canEditTask=false&taskId=${taskId}&serviceItemId=${serviceItemId}`
        );
    } else if (serviceItemId === 1) {
        // 临床拜访
        // window.open(`/#/Business/PromotionPlan/BusinessPerfectInfo/VisitDetailTab?id=${dataId}&taskId=${taskId}`);
        window.open(`/#/Market/VisitPage/ClinicalVisitDetail?companyId=${dataCompanyId}&visitId=${dataId}`);
    } else if (serviceItemId === 2) {
        // 药店拜访
        // window.open(
        //     `/#/Business/PromotionPlan/BusinessPerfectInfo/PharmacyVisitListDetailTab?id=${dataId}&taskId=${taskId}`
        // );
        window.open(`/#/Market/Pharmacy/PharmacyVisitInfo?&visitId=${dataId}`);
    } else if (serviceItemId === 4) {
        // 终端维护
        window.open(
            `/#/Business/PromotionPlan/BusinessPerfectInfo/TerminalMaintenanceDetail?id=${dataId}&taskId=${taskId}`
        );
    } else if ([6, 7, 8, 9, 10].includes(serviceItemId)) {
        // 学术活动类
        window.open(`/#/AcademicPlan/PlanRegistration/RegistrationList/RePlanDetail?id=${dataId}&taskId=${taskId}`);
    }
    // else if ([15, 16, 17].includes(serviceItemId)) {
    //     // 终端调研、商业配送机构调研、竞品信息反馈、收集商业流向数据
    //     window.open(`/#/Business/PromotionPlan/BusinessPerfectInfo/FlowDataDetailTab?id=${dataId}`);
    // } else if (serviceItemId === 14) {
    //     // 收集终端用药信息
    //     window.open(`/#/Business/PromotionPlan/BusinessPerfectInfo/usePharmacyDetail?id=${dataId}`);
    // }
    else if ([23, 24, 25, 26].includes(serviceItemId)) {
        // 药店准入、医院准入
        window.open(`/#/Business/PromotionPlan/BusinessPerfectInfo/AdmittancePlanDetail?id=${dataId}&taskId=${taskId}`);
    } else if (serviceItemId === 3) {
        // 渠道拜访
        // window.open(
        //     `/#/Business/PromotionPlan/BusinessPerfectInfo/ChannelTaskVisitDetail?id=${dataId}&taskId=${taskId}`
        // );
        window.open(`/#/Market/Channel/VisitListChannelInfo?visitId=${dataId}`);
    } else if ([14, 15, 16, 17, 43, 42, 18, 12, 13, 41, 19, 42, 5, 11].includes(serviceItemId)) {
        // 区域药品招标信息收集、收集其他终端库存信息、收集药店库存信息、区域临床路径信息收集、商业渠道维护、收集商业流向数据、收集医院库存信息、学术拜访、患者随访
        window.open(
            `/#/Business/PromotionPlan/BusinessPerfectInfo/CollectInfo?id=${dataId}&taskId=${taskId}&serviceItemId=${serviceItemId}`
        );
    }
}

/**
 * 使用位运算解析value返回开启的项目
 * @param {*} value
 * @returns
 */
export function getEnabledBits(value) {
    if (!value || 'number' !== typeof +value) return [];
    const res = Array.from(value.toString(2))
        .reverse()
        .map((val, index) => {
            return val === '1' ? 2 ** index : 0;
        })
        .filter(Boolean);
    return res;
}
