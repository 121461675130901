import '@babel/polyfill';
import React from 'react';
import ReactDom from 'react-dom';
import './style/animations.css';
import './style/normalize.css';
import './style/public.less';
// import '../public/fonts/iconfont.css'
import request from '@common/http';
import { LocaleProvider } from 'antd';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import { ConfigProvider, Modal, Prompt } from 'hera-ui';
import 'moment/locale/zh-cn';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import { logout } from './common/utils';
import { toMount } from './component/springFrame';
import reduce from './reduce/reduce';
import RouterComponent, { noLoginRouter } from './router/Pages';

window.$ = require('jQuery');
window._ = require('lodash');
window.Immutable = require('immutable');
require('./common/extendAjax');
window.API = require('./common/api');
window.NAME = require('./common/varName');
window.QUERY_STRING = require('query-string');

$(document).on('ajaxError', (e, response) => {
    if (response.status === 401) {
        logout();
    }
});
toMount();

window.addEventListener('storage', (e) => {
    if (e.key === 'USERID') {
        // 延迟300ms，等待用户信息设置完毕，再进行 userId比较
        setTimeout(() => {
            const userId = localStorage.getItem('USERID');
            if (userId !== e.oldValue) {
                const path = window.location.hash.split('?')[0].replace('#', '');
                if (!noLoginRouter.includes(path)) {
                    Prompt.dredgeIntercept(() => {
                        // 放一个标识，登录页面需要刷新
                        window.sessionStorage.setItem('reloadLogin', 'true');
                        window.location.hash = '#/login';
                    });
                    Modal.warning({
                        title: '温馨提示',
                        content: '当前账号登录信息发生变更'
                    });
                }
            }
        }, 300);
    }
    // if (e.key === 'USERID' && e.newValue !== e.oldValue) {

    //     const path = window.location.hash.split('?')[0].replace('#', '');
    //     console.log(e, 'e');
    //     if (!noLoginRouter.includes(path)) {
    //         Prompt.dredgeIntercept(() => {
    //             window.location.hash = '#/login';
    //         });
    //         Modal.warning({
    //             title: '温馨提示',
    //             content: '当前账号登录信息发生变更'
    //         });
    //     }
    // }
});

// console.log('当前环境变量:',process.env.NODE_ENVE)
window.enve = process.env.NODE_ENVE;

/**
 * 金额添加千分符
 * @returns {*}
 * @constructor
 */
String.prototype.ExChangePrice = function () {
    return this.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

/**
 * 日期格式化
 * @param fmt
 * @returns {*}
 */
Date.prototype.format = function (fmt) {
    // author: meizz
    const o = {
        'M+': this.getMonth() + 1, // 月份
        'd+': this.getDate(), // 日
        'h+': this.getHours(), // 小时
        'm+': this.getMinutes(), // 分
        's+': this.getSeconds(), // 秒
        'q+': Math.floor((this.getMonth() + 3) / 3), // 季度
        S: this.getMilliseconds() // 毫秒
    };
    if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, `${this.getFullYear()}`.substr(4 - RegExp.$1.length));
    for (const k in o)
        if (new RegExp(`(${k})`).test(fmt))
            fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : `00${o[k]}`.substr(`${o[k]}`.length));
    return fmt;
};

// 删除指定index的数组，返回删除后的数组
Array.prototype.delete = function (delIndex) {
    const temArray = [];
    for (let i = 0; i < this.length; i++) {
        if (i != delIndex) {
            temArray.push(this[i]);
        }
    }
    return temArray;
};

// 替换所有指定正则的字符串
String.prototype.replaceAll = function (s1, s2) {
    return this.replace(new RegExp(s1, 'gm'), s2);
};

// 往指定索引的数据中插入数据，返回插入后的数据
Array.prototype.insert = function (index, item) {
    this.splice(index, 0, item);
};

/**
 * 解析URL获取查询参数对象
 * @param queryString
 * @returns {ParsedQuery}
 * @constructor
 */
window.GET_PARAM = (queryString = window.location.href) => {
    const index = queryString.indexOf('?');
    if (index === -1) {
        return {};
    }
    const str = queryString.substring(index);
    return QUERY_STRING.parse(str);
};

/**
 * 解析URL获取查询数字参数，如果字符串中包含了非数字（小数点也算非数字）则剔除非数字
 * 因为现实情况，当用户复制粘帖url时，会不小心改动了url上的参数
 * @param key
 * @param queryString
 * @returns {ParsedQuery|*|null}
 * @constructor
 */
window.GET_NUM_PARAM = (key, queryString = window.location.href) => {
    const str = GET_PARAM(queryString)[key];
    if (/^\d+$/.test(str)) {
        return str;
    }
    if (str === null || str === undefined || typeof str === 'object') {
        return null;
    }

    // 从末尾开始剔除非数字
    let result = '';
    str.split('').forEach((char) => {
        if (/^\d+$/.test(char)) {
            result += char;
        }
    });
    return result;
};

/**
 * 简便方法，从URL查询参数中获取ID
 * @param url
 * @returns {T[] | T | null | undefined}
 * @constructor
 */
window.GET_ID = (url = window.location.href) => {
    return GET_PARAM(url)['id'];
};

const store = createStore(
    reduce,
    // 开发环境触发 redux-devtools
    window.__REDUX_DEVTOOLS_EXTENSION__ && process.env.NODE_ENV === 'development'
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : undefined
);

const ConfigProviderProps = {
    request,
    table: {
        pagination: {
            showQuickJumper: true
        }
    }
};

ReactDom.render(
    <LocaleProvider locale={zh_CN}>
        <Provider store={store}>
            <ConfigProvider {...ConfigProviderProps}>
                <RouterComponent />
            </ConfigProvider>
        </Provider>
    </LocaleProvider>,
    document.getElementById('app')
);
